<div id="pageNotFound">

  <div class="content">
    <div class="inner-wrapper">
      <div class="top-title">Oh!</div>

      <div class="main-title">
        4
        <span class="svg-wrap">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149.72 149">
            <g id="ops_badge" data-name="ops badge">
              <polygon class="opsbadge-svg" points="136.99 1.67 139.15 1.67 139.15 7.47 140.41 7.47 140.41 1.67 142.58 1.67 142.58 0.51 136.99 0.51 136.99 1.67"/>
              <path class="opsbadge-svg" d="M148.22.51,146.6,5.22l-.16.49c0-.13-.09-.26-.14-.41L144.7.51h-1.64v7h1.23V2.84l1.57,4.63h1.06l1.58-4.56V7.47h1.22v-7Z"/>
              <path class="opsbadge-svg" d="M39.77,66.75H35c-2.81,0-3.72,2.74-7,11.81-.54,1.66-.08,3.07,2,3.07h4.27a4.07,4.07,0,0,0,4.31-3.07L41.79,70C42.47,68.22,42.67,66.74,39.77,66.75Z"/>
              <path class="opsbadge-svg" d="M81.47,66.89H74.81l-2.74,7.29h6c2.88,0,3-.42,4-2.47l.7-1.8C84.15,66.89,82.62,66.89,81.47,66.89Z"/>
              <path class="opsbadge-svg" d="M74.5,6.77A67.73,67.73,0,1,0,142.23,74.5,67.8,67.8,0,0,0,74.5,6.77ZM46.26,85.14A11.73,11.73,0,0,1,43,86.7a12.88,12.88,0,0,1-3.71.57H20.94a9.07,9.07,0,0,1-3.28-.57,4.39,4.39,0,0,1-2.12-1.56c-.77-1-.69-2.82.26-5.36L20,68.59A10.91,10.91,0,0,1,23.8,63.2a11.69,11.69,0,0,1,3.3-1.53,12.86,12.86,0,0,1,3.7-.57H49.12a9,9,0,0,1,3.27.57,4.41,4.41,0,0,1,2.14,1.53q1.14,1.56-.27,5.39L50,79.78A10.81,10.81,0,0,1,46.26,85.14ZM83,78.53H70.43L68.37,84c-.59,1.72-.63,3-.13,3.9H53.82A9.46,9.46,0,0,0,56.6,84l6.93-18.41a5,5,0,0,0,.16-3.86H89.35c6.09,0,7.37,1.74,6.33,5.08L93.28,73C91.76,76.38,89.88,78.53,83,78.53Zm44.38-11.64H115.1a1.79,1.79,0,0,0-1.77,1c-.27.58-.62,1.56-.66,1.68-.24.65.59.79,1.06.79h11.38s6,0,4.15,5.23l-2.71,6.89c-2.29,5.71-3.55,5.39-8.17,5.39H94.13l2.55-6.47a10.1,10.1,0,0,0,2.62.91,37.64,37.64,0,0,0,4.22.16h9.1a8,8,0,0,0,2-.1,1.15,1.15,0,0,0,.82-.74c.09-.26.69-1.76.81-2.07.39-1-.63-1-1.2-1h-8.85c-2.23,0-1.9,0-3.14,0-2.45,0-5.09-.06-3.58-4.28l2.88-7.21c.74-1.78,2-5.39,10.33-5.39H134l-2.5,5.79C130.74,66.85,127.05,66.89,127.38,66.89Z"/>
              <path class="opsbadge-svg" d="M74.5,0A74.5,74.5,0,1,0,149,74.5,74.49,74.49,0,0,0,74.5,0Zm0,143.64A69.14,69.14,0,1,1,143.64,74.5,69.22,69.22,0,0,1,74.5,143.64Z"/>
            </g>
          </svg>
        </span>
        4
      </div>
    </div>

    <p class="blurb">
      The page you were looking for doesn't exist.<br />
      Return to
      <a href="#">homepage</a>
      or explore
    </p>

    <a href="#" class="ops-btn"><i class="fa fa-angle-left" style="margin-right: 15px;" aria-hidden="true"></i> Return to OPSTrax</a>

  </div>

  <div class="background"></div>

</div>
