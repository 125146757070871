import { Component, OnInit } from '@angular/core';
import { NewsService, INews } from '../../../shared/services/news.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  news: INews;

  constructor(private newsService: NewsService) {
  }

  ngOnInit() {
    this.newsService.getNews().then( news => {
      news = news.filter(n => !n.isPrivate);
      this.news = _.sortBy(news, ['date']).reverse();
    });
  }

}
