import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit {

  announcementItems = [{
    content: 'OPS is announcing the retirement of its TraxPod product.',
    btn: 'Read More',
    path: '/news-and-media/traxpod-to-opstrax-update'
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
