<div class="conainer-fluid">
  <app-announcement *ngIf="showAnnoucement === true"> </app-announcement>
  <div class="row m-0">
    <div
      class="col p-0 left-side"
      [ngClass]="[showAnnoucement ? 'announcement' : '']"
    >
      <app-navigation [(showLogin)]="showLogin"></app-navigation>
      <app-news></app-news>
      <app-carousel></app-carousel>
      <div class="footer">
        <span [routerLink]="['/privacy-policy']"
          >Overall Parts Solutions &copy; {{ currentYear }}. All Rights
          Reserved.</span
        >
        <span>
          <a [routerLink]="['/privacy-policy']">Our Privacy Policy </a>&nbsp;
          <a href="javascript:void(0)" (click)="ccpaPolicy()">CCPA Policy</a>
        </span>
      </div>
    </div>
    <div
      id="compact-login-container"
      [ngClass]="[
        showAnnoucement ? 'announcement' : '',
        showLogin ? 'show' : ''
      ]"
      class="col-lg-3 p-0"
    >
      <app-compact-login-v2></app-compact-login-v2>
    </div>
  </div>
</div>
