import { Component, ElementRef, Input, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { CookieConsentService } from '../../services/cookie-consent.service';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() id: string;
  private element: any;
  isOnPrivacyPage = false;

  constructor(private modalService: CookieConsentService,
              private cookieServ: CookieService,
              private viewportScroller: ViewportScroller,
              private router: Router,
              private el: ElementRef) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    this.setCookiePosition();
    const modal = this;
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }
    document.body.appendChild(this.element);
    this.element.addEventListener('click', e => {
      if (e.target.className === 'ops-modal') {
        modal.close();
      }
    });
    this.modalService.add(this);
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe(() => {
      if (this.router.url.indexOf('privacy-policy') > 0) {
        this.isOnPrivacyPage = true;
      } else {
        this.isOnPrivacyPage = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  setCookiePosition() {
    const cookieElement = document.getElementById('cookieElement');
    window.addEventListener('scroll', () => {
      cookieElement.style.position = 'fixed';
    });
  }

  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('ops-modal-open');
  }

  cookiePolicy() {
    if (this.isOnPrivacyPage) {
      this.viewportScroller.scrollToAnchor('cookies');
      const highlightDiv = document.getElementById('cookies');
      highlightDiv.classList.add('highlight');
      setTimeout(() => {
        highlightDiv.classList.remove('highlight');
      }, 11000);
    } else {
      this.router.navigate(['/privacy-policy', 'cookies']);
    }
    // close cookie consent
    this.element.style.display = 'none';
    document.body.classList.remove('ops-modal-open');
  }

  close(): void {
    // Add cookie when accept terms
    this.cookieServ.set('opsCookie', 'cookieValue', 365);
    this.cookieServ.get('opsCookie');
    // close cookie consent
    this.element.style.display = 'none';
    document.body.classList.remove('ops-modal-open');
  }

  btnClose() {
    this.close();
  }

}
