import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-compact-login',
  templateUrl: './compact-login.component.html',
  styleUrls: ['./compact-login.component.scss']
})
export class CompactLoginComponent implements OnInit {
  @Input() showLoginButton = false;
  username: string;
  password: string;
  error = 'Invalid username or password';
  showError = false;
  currentYear: number = new Date().getFullYear();
  redirectUrl = environment.v2RedirectUrl;
  environment = environment.assets;

  constructor(private authService: AuthService) { }

  ngOnInit() {
  }

  signin() {
// tslint:disable-next-line: no-string-literal
    const formRef = document.forms['loginForm2'];
    formRef.submit();
  }

}
