import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class JsLoader {
  environment = environment.assets;
  salesForceScript = this.environment + 'products/portal/scripts/salesforce.js';
  recaptchaScript = 'https://www.google.com/recaptcha/api.js';
  constructor(
  ) { }

  loadSalesForceScript(): boolean {
    let isFound = false;
    const scripts = document.getElementsByTagName('script');

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('id') != null && scripts[i].getAttribute('id').includes('salesforceScript')) {
        isFound = true;
      }
    }

    if (!isFound) {
      const node = document.createElement('script');
      node.src = this.salesForceScript;
      node.id = 'salesforceScript';
      node.async = false;
      document.getElementsByTagName('head')[0].appendChild(node);
    }
    return isFound;
  }

  loadRecaptchaScript() {
      const node = document.createElement('script');
      node.src = this.recaptchaScript;
      node.id = 'recaptcha';
      node.async = false;
      document.getElementsByTagName('head')[0].appendChild(node);
  }
}
