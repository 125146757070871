<div id="login">
  <div class="login-content" style="max-width: 200px;">

    <div style="margin-bottom: 0 !important;" class="ops-logo">
      <img draggable="false" [src]="environment + 'shared/img/product-logos/png/opsbadge.png'" alt="OPS">
      <div>Support</div>
    </div>

    <div style="margin: 20px 0; opacity: .7; user-select: none;">Type your name and the Support Key received from your Technician and click Continue to proceed.</div>

    <form ngNoForm method="post" action="https://fastsupport.gotoassist.com/download/customerDownload" name="ssid" id="ssid">


      <span>
        <label for="name">name</label>
        <input type="text" name="name" id="name" placeholder="Enter Name" />
      </span>

      <span>
        <label for="supportSessionId">Support Key</label>
        <input type="text" name="supportSessionId" id="supportSessionId" placeholder="Enter Your Support Key" />
      </span>

      <button type="submit" class="btn" style="margin-top: 0;" >
        <span>CONTINUE</span>
      </button>

      <input type="hidden" id="showLegalInfo" name="showLegalInfo" value="1">
      <input type="hidden" id="maskIdField" name="maskIdField" value="1">
      <input type="hidden" id="useEmbeddedLauncher" name="useEmbeddedLauncher" value="0">

    </form>


    <div class="copyright">Overall Parts Solutions &copy; {{currentYear}}. All Rights Reserved.</div>

  </div>

</div>
