<div id="login">

    <div class="login-content" *ngIf="showForm">
      <div class="ops-logo">
        <img draggable="false" [src]="environment + 'shared/img/product-logos/png/opsbadge.png'" alt="OPS">
        <div>Technologies</div>
      </div>
      <form (ngSubmit)="loginAndRedirect()" >
        <span>
          <label [ngClass]="(showError) ? 'error-label':''" for="username">Token</label>
          <input [ngClass]="(showError) ? 'error-input':''" type="text" name="token" [(ngModel)]="token" placeholder="Enter Token" />
        </span>

        <p [ngClass]="(showError) ? 'error':''" *ngIf="showError">{{error}}</p>

        <a class="forgot-password" href="https://admin.opstrax.com/admind/forgotLogin" target="_blank">Forgot Password?</a>

        <button type="submit" [class]="(isLoading)? '':'btn'" [disabled]="isLoading">
          <span *ngIf="!isLoading">LOGIN</span>
          <span *ngIf="isLoading"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>
        </button>

      </form>

      <div class="copyright">Overall Parts Solutions &copy; {{currentYear}}. All Rights Reserved.</div>

    </div>

  </div>
