import { Component, OnInit } from '@angular/core';
import { Auth2Service } from 'src/app/shared/services/auth2.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-idsrv4-login',
  templateUrl: './idsrv4-login.component.html',
  styleUrls: ['./idsrv4-login.component.scss']
})
export class Idsrv4LoginComponent implements OnInit {
  environment = environment.assets;
  currentYear: number = new Date().getFullYear();

  constructor(private authService: Auth2Service) { }

  ngOnInit(): void {
  }


  login() {
    this.authService.login();
  }
  logout() {
    this.authService.logout();
  }

}
