<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a style="margin: 4px 15px !important;" class="navbar-brand" href="#">
    <img draggable="false" [src]="'https://assets.opstrax.com/shared/img/product-logos/png/opsbadge-tagline.png'" width="35px"
      height="auto" alt="ops-logo">
  </a>

  <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
    <div class="navbar-nav">

      <a class="nav-item nav-link" [routerLink]="['/benefits']" routerLinkActive="router-link-active">Benefits of
        OPS</a>

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          About
        </a>
        <div class="dropdown-menu ops-dropdown" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" *ngFor="let item of aboutItems" [routerLink]="item.path">
            <div>{{item.title}}</div>
            <small>{{item.subtitle}}</small>
          </a>
        </div>
      </li>

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          Solutions
        </a>
        <div class="dropdown-menu ops-dropdown mega-dropdown" aria-labelledby="navbarDropdown">
          <div class="row">
            <div class="col" *ngFor="let solution of Solutions">
              <h6>{{solution.section}}</h6>

              <a class="dropdown-item" *ngFor="let product of solution.products" (click)="redirect(product.path)"
                [routerLink]="product.path">
                <div>{{product.name}}</div>
                <small>{{product.description}}</small>
              </a>
            </div>
          </div>
        </div>
      </li>

      <a class="nav-item nav-link" [routerLink]="['/news-and-media']">News & Media</a>

      <a class="nav-item nav-link" href="https://register.opstrax.com/register.html" target="_blank">Register</a>

      <!-- <a class="nav-item nav-link" href="http://www.opstrax.com/status/ops/subscribe.cfm">Shop Registration</a>

      <a class="nav-item nav-link" href="http://register.opstrax.com/register/dealerRegistration/index">Dealer Registration</a> -->

    </div>
  </div>

  <div class="right-content">

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup"
      aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- [routerLink]="'/support'" 
    <a id="support" class="nav-item nav-link support" [routerLink]="['/support']"><i class="ops ops-support"></i>Help & Support</a>-->
    <a class="nav-item nav-link" href="https://v3.opstrax.com/opsportal/#/support" target="_blank">Help & Support</a>


    <div *ngIf="!isHomeRoute()" class="phone-number">
      <i class="fa fa-phone"></i>
      877-USE-TRAX</div>

    <a [ngClass]="(isHomeRoute())? 'home-login':'other-login'" class="nav-item nav-link" (click)="setLoginNavigation()">SIGN IN</a>

  </div>

</nav>
