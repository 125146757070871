import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as jwt_decode from 'jwt-decode';

const tokenEndPoint = 'oauth2/token';

export interface RequestBody {
  grant_type: string;
  client_Id: string;
  cfid: string;
}

export interface User {
  id: number;
  grantType: string;
  features: string[];
  featuresCodes: string[];
  companyId: number;
  defaultContact: number;
  contactId: number;
  fullName: string;
  timeZone: string;
  userName: string;
  userType: number;
  emailAddresses: string[];
  shippingVendorId: number;
  manufacturers: string[];
  showTutorial: number;
  shopId: number;
  firstName: string;
  lastName: string;
  companyName: string;
  userGuid: string;
  marketId: number;
  cfid?: string;
}


@Injectable()
export class AuthService {
  user: User;
  token = '';
  accessToken = '';

  constructor(
    private http: HttpClient,
  ) { }

  login(username: string, password: string): Observable<string> {
    const url = `${environment.identityApiBaseUrl}${tokenEndPoint}`;
    const body = new HttpParams()
      .set('grant_type', 'opstrax')
      .set('client_Id', environment.clientId)
      .set('username', username)
      .set('password', password)
      .set('environment', environment.environment);

    return this.http.post<any>(url, body, this.getOptions())
      .pipe(map(res => {
        if (!res.access_token) {
          return null;
        }
        const accessToken = this.mapTokenResponse(res);
        this.decodeToken(accessToken);
        this.accessToken = accessToken;
        return accessToken;
      }));
  }

  getAccessToken() {
    return this.accessToken;
  }

  ehiLogin(contactId: number): Observable<string> {
    const url = `${environment.identityApiBaseUrl}${tokenEndPoint}`;
    const body = new HttpParams()
      .set('grant_type', 'test')
      .set('client_Id', environment.clientId)
      .set('password', '5UperK3y')
      .set('id', contactId.toString());

    return this.http.post<any>(url, body, this.getOptions())
      .pipe(map(res => {
        if (!res.access_token) {
          return null;
        }
        const accessToken = this.mapTokenResponse(res);
        this.decodeToken(accessToken);
        return accessToken;
      }));
  }

  adminPanelLogin(token: string): Observable<AdminPanelResponse> {
    const url = `${environment.identityApiBaseUrl}${tokenEndPoint}`;
    const body = new HttpParams()
      .set('grant_type', 'adminpanel')
      .set('token', token)
      .set('client_Id', environment.clientId);

    return this.http.post<AdminPanelResponse>(url, body, this.getOptions())
      .pipe(map(res => {
        if (!res.access_token) {
          return null;
        }
        const accessToken = this.mapTokenResponse(res);
        this.decodeToken(accessToken);
        this.accessToken = accessToken;
        return { access_token: res.access_token, contactType: this.user.userType };
      }));
  }

  private mapTokenResponse(res: any) {
    if (res.userName && res.access_token) {
      return res.access_token;
    } else {
      return null;
    }
  }

  private decodeToken(accessToken: string) {
    const decodedToken = jwt_decode(accessToken);
    this.user = {
      id: +decodedToken.ContactId,
      grantType: decodedToken.GrantType,
      features: decodedToken.Features,
      featuresCodes: decodedToken.role,
      companyId: +decodedToken.CompanyId,
      defaultContact: 0,
      contactId: +decodedToken.ContactId,
      fullName: decodedToken.FullName,
      timeZone: undefined,
      userName: decodedToken.unique_name,
      userType: +decodedToken.UserType,
      emailAddresses: [],
      shippingVendorId: +decodedToken.ShippingVendorId,
      manufacturers: decodedToken,
      showTutorial: undefined,
      shopId: undefined,
      firstName: decodedToken.FirstName,
      lastName: decodedToken.LastName,
      companyName: decodedToken.CompanyName,
      userGuid: undefined,
      marketId: 0,
      cfid: decodedToken.Cfid
    };
    console.log(this.user);

  }


  private getOptions() {
    const httpOptions = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    };
    return httpOptions;
  }

  getCurrentUser(): User {
    return this.user;
  }

}

export interface AdminPanelResponse {
  contactType: number;
  access_token: string;
}


