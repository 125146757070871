import { EhiService } from './../../shared/services/ehi.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './../../shared/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-ehi-login',
  templateUrl: './ehi-login.component.html',
  styleUrls: ['./ehi-login.component.scss']
})
export class EhiLoginComponent implements OnInit {
  username: string;
  password: string;
  error = '';
  showError = false;
  isLoading = false;
  currentYear: number = new Date().getFullYear();
  showForm = false;
  companyId: number;
  contactId: number;
  jwtToken: string;
  uniqueId: string;
  environment = environment.assets;

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private ehiService: EhiService,
    private router: Router) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      const companyId = params.companyId ?? null;
      const token = params.token ?? null;
      const uniqueId = params.uniqueId ?? '';

      if ((companyId === null) && (token === null)) {
        this.router.navigate(['page-not-found']);
      }
      this.processLogin(companyId, token, uniqueId);
    });
  }

  processLogin(companyId, token, uniqueId) {
    this.companyId = companyId;
    if (token) {
      // EHI TOKEN LOGIN
      this.uniqueId = uniqueId;
      this.getContactId(companyId, token);
    } else {
      // EHI REQUEST TOKEN
      this.showForm = true;

    }
  }

  getContactId(companyId: number, token: string) {
    this.ehiService.getContactId(companyId, token).subscribe(resp => {
      if (resp) {
        this.contactId = resp.contactId;
        this.loginAndRedirect();
      } else {
        if (!this.jwtToken) {
          this.error = 'Invalid Company or Token';
          this.showForm = true;
          this.showError = true;
        }

      }
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        // A client-side or network error occurred.
        this.error = err.error.message;
        this.showError = true;
      } else {
        // Backend returns unsuccessful response codes such as 404, 500 etc.
        this.error = err.status + ' --- ' + err.error;
        this.showError = true;
      }
    });
  }

  loginAndRedirect() {
    this.isLoading = true;
    this.authService.ehiLogin(this.contactId).subscribe(accessToken => {
      const user = this.authService.getCurrentUser();
      this.isLoading = false;
      window.open(environment.shoptraxBaseUrl + 'login?' + 'atoken=' + accessToken + '&unqFile=' + this.uniqueId, '_self');
      //  window.open('http://localhost:8081/#/login?' + 'atoken=' + accessToken + '&unqFile=' + this.uniqueId,'_self');
    },
      error => {
        this.isLoading = false;
        this.showError = true;
      });
  }

  getToken() {
    this.showError = false;
    this.ehiService.getToken(+this.companyId, this.username, this.password).subscribe(resp => {
      if (resp) {
        this.contactId = resp.contactId;
        this.jwtToken = resp.jwtToken;
        this.postMessageToParentWindow();
      } else {
        this.error = 'Invalid username or password';
        this.showError = true;
      }
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        // A client-side or network error occurred.
        this.error = err.error.message;
        this.showError = true;
      } else {
        // Backend returns unsuccessful response codes such as 404, 500 etc.
        this.error = err.status + ' --- ' + err.error;
        this.showError = true;
      }
    });
  }

  postMessageToParentWindow() {
    window.parent.postMessage({ companyId: this.companyId, jwtToken: this.jwtToken }, '*');
    window.top.postMessage(
      JSON.stringify({ companyId: this.companyId, jwtToken: this.jwtToken }),
      '*'
    );

  }

}
