import { Auth2Service } from './../../shared/services/auth2.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signin-callback',
  templateUrl: './signin-callback.component.html',
  styleUrls: ['./signin-callback.component.scss']
})
export class SigninCallbackComponent implements OnInit {

  ronumber: string;

  constructor(private authService: Auth2Service, private router: Router,
              private commonApiService: CommonApiService) { }

  ngOnInit(): void {
    this.authService.completeAuthentication().then(user => {
      const currentUser = this.authService.currentUser;
      switch (currentUser.userType) {
        case 1:
          this.commonApiService.GetNavigationBarLinks({ id: 27, encrypted: true }).subscribe(result => {
            result.map(x => {
              if (x.app_id === 27) {
                if (this.ronumber === undefined) {
                  // window.location.href = x.url + 'clientid-login?jwt=' + this.authService.getAccessToken();
                  window.location.href = 'http://localhost:4500/#/auth';
                } else {
                  window.location.href = x.url + 'login?atoken=' + this.authService.getAccessToken() + '&ronumber=' + this.ronumber;
                }
              }
            });
          });
          break;
        case 2:
          this.commonApiService.GetNavigationBarLinks({ id: 25, encrypted: true }).subscribe(result => {
            result.map(x => {
              if (x.app_id === 25) {
                window.location.href = x.url + 'clientid-login?jwt=' + this.authService.getAccessToken();
              }
            });
          });
          break;
        case 3:
          // this.amISarvesh(this.authService.getAccessToken()); TODO CHECH THIS
          break;
        case 7:
          this.signinV2();
          break;
        case 4:
          this.commonApiService.AddRedirectToken({ url: environment.dispatchRedirectUrl }).subscribe(data => {
            const grailsUrl = environment.dispatchRedirectUrl + 'crossAppAuth/jump?token=' + data.token;
            window.open(
              grailsUrl,
              '_blank'
            );
          });
          break;
      }
    });
  }

  signinV2() {
    // tslint:disable-next-line: no-string-literal
    const formRef = document.forms[0];
    formRef.action = environment.v2RedirectUrl;
    formRef.method = 'post';
    formRef.submit();
  }


}
