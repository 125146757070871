import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { UserManager, User, UserManagerSettings } from 'oidc-client';
import { Subject, Observable } from 'rxjs';
import { User as OpsUser } from './auth.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

const recoverEndpoint = 'redirect/recover';
const refreshTokenKey = 'ops.id.refreshToken';
const accessTokenKey = 'ops.id.accessToken';
const expiresTokenKey = 'ops.id.accessTokenExpiration';
const currentUserKey = 'ops.id.currentUser';

@Injectable({
  providedIn: 'root'
})
export class Auth2Service {

  // tslint:disable-next-line:variable-name
  private userManager: UserManager;
  // tslint:disable-next-line: variable-name
  private user: User;
  // tslint:disable-next-line:variable-name
  private loginChangedSubjet = new Subject<boolean>();

  public loginChanged = this.loginChangedSubjet.asObservable();

  private xUser: User = null;

  stsSettings = {} as UserManagerSettings;

  currentUser = {} as OpsUser;

  constructor(
    private http: HttpClient) {

    this.stsSettings = { ...environment.stsSettings };

    this.userManager = new UserManager(this.stsSettings);

    // when access tokens arrive
    this.userManager.getUser().then(user => {
      this.xUser = user;
      this.mapUserToStore();
    });

    this.userManager.events.addAccessTokenExpired(_ => {
      this.loginChangedSubjet.next(false);
      console.log('token expired');
    });
    // when new response occurs
    this.userManager.events.addUserLoaded(user => {
      this.xUser = user;
      this.mapUserToStore();
    });
  }

  updateAcrValues(value: any) {
    const newSettings = { ...this.stsSettings };
    newSettings.acr_values = value;
    this.userManager = new UserManager(newSettings);
  }

  login() {
    return this.userManager.signinRedirect();
  }

  isLoggedIn(): boolean {
    return !!this.xUser && !this.xUser.expired;
  }

  getClaims(): any {
    return this.xUser.profile;
  }
  getAuthorizationHeaderValue(): string {
    if (this.isLoggedIn()) {
      return `${this.xUser.token_type} ${this.xUser.access_token}`;
    } else {
      return '';
    }
  }
  getAccessToken(): string {
    if (this.isLoggedIn()) {
      return `${this.xUser.access_token}`;
    } else {
      return '';
    }
  }
  startAuthentication(): Promise<void> {
    return this.userManager.signinRedirect();
  }
  completeAuthentication(): Promise<void> {
    return this.userManager.signinRedirectCallback().then(user => {
      this.xUser = user;
      this.mapUserToStore();
    });
  }

  // isLogedIn(): Promise<boolean> {
  //   return this.userManager.getUser().then(user => {
  //     const loged = !!user && !user.expired;
  //     if (this.user !== user) {
  //       this.loginChangedSubjet.next(loged);
  //     }
  //     this.user = user;
  //     return loged;
  //   });
  // }

  completeLogin() {
    return this.userManager.signinRedirectCallback().then(user => {
      this.user = user;
      this.mapUserToStore();
      this.loginChangedSubjet.next(!!user && !user.expired);
      return user;
    });
  }

  logout() {
    this.userManager.signoutRedirect();
  }

  completeLogout() {
    this.xUser = null;
    this.loginChangedSubjet.next(false);
    return this.userManager.signoutRedirectCallback();
  }

  mapUserToStore() {
    if (this.xUser) {
      const currentUser: OpsUser = {
        id: +this.xUser.profile.id,
        features: this.xUser.profile.features.split(','),
        featuresCodes: this.xUser.profile.featuresCodes.split(','),
        companyId: +this.xUser.profile.companyId,
        defaultContact: 0,
        contactId: +this.xUser.profile.id,
        fullName: this.xUser.profile.fullName,
        timeZone: undefined,
        userName: this.xUser.profile.userName,
        userType: +this.xUser.profile.userType,
        emailAddresses: this.xUser.profile.emailAddresses,
        shippingVendorId: +this.xUser.profile.shippingVendorId,
        manufacturers: this.xUser.profile.manufacturers,
        showTutorial: undefined,
        shopId: this.xUser.profile.companyId,
        firstName: this.xUser.profile.firstName,
        lastName: this.xUser.profile.lastName,
        companyName: this.xUser.profile.companyName,
        userGuid: undefined,
        marketId: 0,
        grantType: '',
        cfid: null
      };
      this.currentUser = currentUser;
    }

  }

  redirectExternal(guid: string): Observable<RedirectDataC> {
    const requestData = { guid };
    const options = { headers: new HttpHeaders({}) };
    const url = `${environment.identityApiBaseUrl}${recoverEndpoint}`;
    return this.http.post<RedirectDataC>(url, requestData, options)
      .pipe(map(res => {
        if (res) {
          const result: RedirectDataC = res as RedirectDataC;
          if (result) {
            // this.localStorageService.set(accessTokenKey, result.AccessToken);
            // this.localStorageService.set(expiresTokenKey, result.ExpiresToken);
            // this.localStorageService.set(refreshTokenKey, result.RefreshToken);
            // const currentUser = JSON.parse(result.CurrentUser);
            // this.localStorageService.set(currentUserKey, currentUser);
            return result;
          }
        }
      }));
  }

  clearUserFromLocalStorage() {
    // this.localStorageService.clearAll();
  }

}

interface RedirectDataC {
  AccessToken: string;
  ExpiresToken: string;
  RefreshToken: string;
  CurrentUser: string;
  Url: string;
  Parameters: string;
}
