<div id="login">
    <div class="back-web pull-right">
      <a [routerLink]="['/ops']">
        <button class="back-btn"><i class="fa fa-arrow-left"></i>Back to OPS Site</button>
      </a>
    </div>
    <div class="login-content">
      <div class="ops-logo">
        <img draggable="false" [src]="environment + 'shared/img/product-logos/png/opsbadge.png'" alt="OPS">
        <div>Technologies</div>
      </div>
      <form (ngSubmit)="signin()" name="loginForm" >
        <span>
          <label [ngClass]="(showError) ? 'error-label':''" for="username">Username</label>
          <input [ngClass]="(showError) ? 'error-input':''" type="text" name="username" [(ngModel)]="username" id="username"
            placeholder="Enter Username" />
        </span>
  
        <span>
          <label [ngClass]="(showError) ? 'error-label':''" for="password">password</label>
          <input [ngClass]="(showError) ? 'error-input':''" type="password" name="password" [(ngModel)]="password" id="password"
            style="-webkit-text-security: circle;" placeholder="Enter Password" />
        </span>
        <!--<input  type="text" name="ronumber" [(ngModel)]="ronumber" id="ronumber" style="display: none"/>-->
  
        <!-- <label class="input">
          <input name="rememberMe" type="checkbox" class="input__field">
          <span class="input__text checkbox"> Remember Me</span>
        </label> -->
  
        <a class="forgot-password" href="https://admin.opstrax.com/admind/forgotLogin" target="_blank">Forgot
          Password?</a>
  
        <button type="submit" [class]="(isLoading)? '':'btn'" [disabled]="isLoading">
          <span *ngIf="!isLoading">LOGIN</span>
          <span *ngIf="isLoading"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>
        </button>
  
      </form>
  
      <div class="copyright">Overall Parts Solutions &copy; {{currentYear}}. All Rights Reserved.</div>
  
    </div>
  
  </div>
