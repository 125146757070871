import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.scss']
})
export class AdsComponent implements OnInit {

  environment = environment.assets;

  adsItems = [{
    img: this.environment + 'products/portal/img/ads/autobahn/autobahn.gif',
    website: 'www.shopautobahn.com',
    path: 'https://shopautobahn.com/'
    }
  ];

  constructor() { }

  ngOnInit() {
  }

  goToLink(url: string) {
    window.open(url, '_blank');
  }

}
