<app-navigation></app-navigation>

<div id="subscribe">
  <div class="container">
    <div class="c-content-title-1">
      <h3 class="c-font-uppercase c-font-bold">Subscribe</h3>
      <hr>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h4>Join the largest network of shops and parts suppliers. Connect with us to enhance your business today!</h4>
        <p>OPS offers a variety of products aimed at improving your business workflow and increasing your bottom line.
          Add a check-mark next to the list of products you are interested in, and an OPS specialist will contact within
          24 hours to help find the right solution for your business.
        </p>
      </div>
      <div class="col-md-12">
        <form method="POST">
          <div class="row">
            <div class="col-md-5">
              <h4>Are you a...</h4>
              <select id="00N370000066I7e" name="00N370000066I7e" title="Lead Type" class="form-control">
                <!--<option value="">--None--</option>--->
                <option value="Body Shop">Body Shop</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="row mt-15">
                <div class="col-md-12">
                  <h4>Your Information</h4>
                  <h6 class="mt-15" for="first_name">First Name</h6>
                  <input id="first_name" maxlength="40" name="first_name" size="20" type="text" class="form-control">
                </div>
              </div>
              <div class="row mt-15">
                <div class="col-md-12">
                  <h6 for="last_name">Last Name:</h6>
                  <input type="text" class="form-control" id="last_name" maxlength="80" name="last_name" size="20">
                </div>
              </div>
              <div class="row mt-15">
                <div class="col-md-12">
                  <h6 for="email">E-mail Address:</h6>
                  <input type="text" id="email" maxlength="80" name="email" size="20" class="form-control">
                </div>
              </div>
              <div class="row mt-15">
                <div class="col-md-12">
                  <h6>Phone Number:</h6>
                  <input type="text" class="form-control" id="phone" maxlength="40" name="phone" size="20">
                </div>
              </div>
              <div class="row mt-15">
                <div class="col-md-12">
                  <h6>Title:</h6>
                  <input type="text" class="form-control" id="title" maxlength="40" name="title" size="20">
                </div>
              </div>
              <div class="row mt-15">
                <div class="col-md-12">
                  <h6>Number of Users:</h6>
                  <select class="form-control" title="Number of Users">
                    <option value="">--None--</option>
                    <option value="1-5">1-5</option>
                    <option value="5-10">5-10</option>
                    <option value="10+">10+</option>
                  </select>
                </div>
              </div>
              <div class="row mt-15">
                <div class="col-md-12">
                  <h6>Estimating System:</h6>
                  <select class="form-control" title="Estimating System">
                    <option value="">--None--</option>
                    <option value="ADP (Audatex)">ADP (Audatex)</option>
                    <option value="CCC (Pathways, CCC One)">CCC (Pathways, CCC One)</option>
                    <option value="Mitchell (Ultramate, Mitchell Repair Center)">Mitchell (Ultramate, Mitchell Repair
                      Center)
                    </option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div class="row mt-15">
                <div class="col-md-12">
                  <h6>Dealer Management System:</h6>
                  <select class="form-control" title="DMS Type">
                    <option value="">--None--</option>
                    <option value="ADP">ADP/CDK</option>
                    <option value="Arkona/Opentrack">Arkona/Opentrack</option>
                    <option value="DPC Integrations">DPC Integrations</option>
                    <option value="DMI – ADP Allinace">ADP Allinace</option>
                    <option value="DMI – Automate">Automate</option>
                    <option value="DMI – Autosoft">Autosoft</option>
                    <option value="DMI – IBS (ASW)">IBS (ASW)</option>
                    <option value="DMI - Lightyear">Lightyear</option>
                    <option value="DMI – UCS">UCS</option>
                    <option value="DMI - PBS">PBS</option>
                    <option value="InfinitiNet">InfinitiNet</option>
                    <option value="Reynolds">Reynolds</option>
                    <option value="UCS">UCS</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row mt-15">
                <div class="col-md-12">
                  <h4>Company Information</h4>
                  <h6 class="mt-15" for="company">Company Name:</h6>
                  <input type="text" class="form-control" id="company" maxlength="40" name="company" size="20">
                </div>
              </div>
              <div class="row mt-15">
                <div class="col-md-12">
                  <h6>Street:</h6>
                  <textarea name="street" class="form-control"></textarea>
                </div>
              </div>
              <div class="row mt-15">
                <div class="col-md-12">
                  <h6 for="zip">Zip:</h6>
                  <input type="text" class="form-control" id="zip" maxlength="20" name="zip" size="20">
                </div>
              </div>
              <div class="row mt-15">
                <div class="col-md-8">
                  <h6 for="city">City:</h6>
                  <input type="text" class="form-control" id="city" maxlength="40" name="city" size="20">
                </div>
                <div class="col-md-4">
                  <h6 for="state">State/Province:</h6>
                  <select class="form-control" id="state" name="state" size="5" type="text">
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
              </div>
              <div class="row mt-15">
                <div class="col-md-12">
                  <h4 class="mt-30">Select the below products you're interested in...</h4>
                  <div class="row mt-30">
                    <div class="col-md-12">
                      <h6>Product of Interest:</h6>
                      <select multiple="multiple" title="Product of Interest" class="form-control">
                        <option value="OPSTrax">OPSTrax</option>
                        <option value="ValuTrax">ValuTrax</option>
                        <option value="TraxPod">TraxPod</option>
                        <option value="DeliveryTrax">DeliveryTrax</option>
                        <option value="TraxDrive">TraxDrive</option>
                        <option value="BackTrax">BackTrax</option>
                        <option value="TraxScan">TraxScan</option>
                        <option value="OPSLogistix">OPSLogistix</option>
                        <option value="OPSFly">OPSFly</option>
                        <option value="OPS Analytics">OPS Analytics</option>
                        <option value="Integrations">Integrations</option>
                        <option value="Consultation">Consultation</option>
                        <option value="Training">Training</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3" style="display:none ;">
                <label for="lead_source"></label>
                <select class="form-control" id="lead_source" name="lead_source">
                  <option value="Subscriber Request">Subscriber Request</option>
                </select>
              </div>
              <div class="row">
                <div class="col-md-4 mt-30">
                  <button class="btn btn-lg btn-primary">SUBMIT</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<app-footer></app-footer>
