<app-navigation></app-navigation>
<div id="benefits">
  <div class="hero">
    <div class="container">
      <h3>The Original. The Now. The Future <br> of Parts Procurement.</h3>
      <div class="ops">
        <img class="img top-left d-none d-sm-none d-md-none d-lg-block"
          [src]="assetsUrl.beam1" draggable="false">
        <div class="section top-left d-none d-sm-none d-md-none d-lg-block">
          <h3>Procurement</h3>
          <p>Experts in supply-chain<br />solutions for your shop or<br />supplier operation.</p>
        </div>
        <img class="img bottom-left d-none d-sm-none d-md-none d-lg-block"
          [src]="assetsUrl.beam2" draggable="false">
        <div class="section bottom-left d-none d-sm-none d-md-none d-lg-block">
          <h3>Support</h3>
          <p>20+ Years improving<br />our clients day-to-day<br /> operations.</p>
        </div>
        <div class="ops-logo">
          <img [src]="assetsUrl.opsBadge"
            draggable="false">
            <div>(oh&bull;pea&bull;ehs)</div>
            <!-- <img (click)="onOPS()" [src]="environment + 'shared/img/product-logos/png/opsbadge.png'"
            draggable="false"> -->
          <!-- <div (click)="onOPS()">(oh&bull;pea&bull;ehs) <i *ngIf="isTalking" class="fa fa-volume-up"
              aria-hidden="true"></i></div> -->

        </div>
        <img class="img top-right d-none d-sm-none d-md-none d-lg-block"
          [src]="assetsUrl.beam3" draggable="false">
        <div class="section top-right d-none d-sm-none d-md-none d-lg-block">
          <h3>Logistics</h3>
          <p>Innovative services and<br />technologies proven<br />throughout the collision<br />repair industry.</p>
        </div>

        <img class="img bottom-right d-none d-sm-none d-md-none d-lg-block"
          [src]="assetsUrl.beam4" draggable="false">
        <div class="section bottom-right d-none d-sm-none d-md-none d-lg-block">
          <h3>Expertise</h3>
          <p>Specialized experience in<br />shop-supplier relations<br />trusted to increase efficiency.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="video-whyops">
    <div class="container">
      <h3>why ops</h3>
      <hr>
      <iframe src="https://player.vimeo.com/video/233883465" width="640" height="360" frameborder="0"
        allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
  </div>
  <div class="partners">
    <div class="container">
      <h3>A FEW OF OUR PARTNERS</h3>
      <hr>
      <div class="logos-container">
        <owl-carousel-o [options]="customOptions">
          <ng-container *ngFor="let partner of partners; let i = index">
            <ng-template carouselSlide [id]="partner.name">
              <img class="slide-image {{partner.name}}" [title]="partner.name" [src]="partner.logo">
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
  <div class="technology">
    <div class="container">
      <h3>Our Technology Suite</h3>
      <hr>
      <div class="row">
        <div class="col-md-4 col-sm-6" *ngFor="let tech of technologies">
          <div class="technology-container">
            <div class="icon-wrapper">
              <div [style.backgroundImage]="'url('+tech.icon+')'"></div>
            </div>
            <a [routerLink]="tech.path">
              <img [src]="tech.logo">
            </a>
            <p>{{tech.description}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="whyops">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <div class="title">
            <h3>Why OPS?</h3>
            <hr>
          </div>
          <div class="content">
            Our powerful supply-chain management solutions benefit shops and parts suppliers by enhancing relations,
            reducing costs and fueling employees' productivity.
          </div>
          <button [routerLink]="'/about'">Explore</button>
          <img [src]="assetsUrl.explore">
        </div>
        <div class="col-md-5">
          <div class="ops-carousel carousel" id="opsCarouse">
            <a class="tab" data-toggle="collapse" data-toggle="collapse" href="#procurement" role="button"
              aria-expanded="true" aria-controls="collapseExample">Parts Procurement</a>
            <div class="collapse show" id="procurement" data-parent="#opsCarouse">
              <div class="content-padding"> Our products and services tackle inefficiencies within the parts
                supply-chain, allowing you to
                quickly
                adapt to changes within the collision repair industry.
              </div>
            </div>
            <a class="tab collapsed" data-toggle="collapse" data-toggle="collapse" href="#logistics" role="button"
              aria-expanded="false" aria-controls="collapseExample">Logistics &amp; Delivery</a>
            <div class="collapse" id="logistics" data-parent="#opsCarouse">
              <div class="content-padding">
                We consider each point in the parts supply-chain process as an opportunity for further efficiencies.
              </div>
            </div>
            <a class="tab collapsed" data-toggle="collapse" data-toggle="collapse" href="#analytics" role="button"
              aria-expanded="false" aria-controls="collapseExample">Analytics &amp; Metrics</a>
            <div class="collapse" id="analytics" data-parent="#opsCarouse">
              <div class="content-padding">
                Our analytics tools provide managers data on suppliers' performance and up-to-the-minute information on
                employees productivity.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
