<app-navigation></app-navigation>

<div id="careerDetail">
  <div class="header" [ngStyle]="{background: 'url(' + environment + career?.banner + ')'}"></div>
  <div class="container">
    <h3>{{career?.position}}</h3>
    <hr>
    <h6>Job Description</h6>
    <p>{{career?.description}}</p>
    <div class="row">
      <div class="col-md-6" *ngFor="let requirements of career?.requirementColumns">
        <div *ngFor="let items of requirements.items">
          <h6>{{items?.title}}</h6>
          <p *ngIf="items?.subtitle">{{items?.subtitle}}</p>
          <ul>
            <li *ngFor="let content of items?.content">{{content.item}}
              <ul *ngIf="content.subItem">
                <li *ngFor="let a of content.subItem; let i = index">{{a}}</li>
              </ul>
            </li>

          </ul>
        </div>
      </div>
    </div>
    <div style="margin-top: 40px">
      <button (click)="onBack()" class="secundary"> <i class="fa fa-fw fa-arrow-left"></i> Back to Careers</button>
      <button class="primary" (click)="onApply()"> <i class="fa fa-fw fa-envelope"></i> Apply Now</button>
    </div>
  </div>
</div>

<app-footer></app-footer>
