import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private http: HttpClient) { }

  send(request) {
    const url = `${environment.opsEmailsUrl}/email/contact-us`;
    const body = new HttpParams()
      .set('firstName', request.firstName)
      .set('lastName', request.lastName)
      .set('jobTitle', request.jobTitle)
      .set('email', request.email)
      .set('phoneNumber', request.phoneNumber)
      .set('companyName', request.companyName)
      .set('website', request.website)
      .set('city', request.city)
      .set('state', request.state)
      .set('zipCode', request.zipCode)
      .set('industry', request.industry)
      .set('interests', request.interests);

    return this.http.post<any>(url, body, this.getOptions());
  }

  sendSupportEmail(request): Observable<any> {

    const url = `${environment.opsEmailsUrl}/support/request`;
    const body = new HttpParams()
      .set('name', request.name)
      .set('orgid', request.orgid)
      .set('phoneNumber', request.phone)
      .set('email', request.email)
      .set('subject', request.subject)
      .set('wholeSalerType', request.wholeSalerType)
      .set('OEMDealerCode', request.oemDealerCode)
      .set('body', request.description);

    return this.http.post<any>(url, body, this.getOptions());
  }

  private getOptions() {
    const httpOptions = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
    };
    return httpOptions;
  }

}
