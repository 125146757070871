import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { EventEmitService, GenericEvent, GENERIC_EVENT_TYPE } from 'src/app/shared/services/event-emit.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  OpsAnniversary: number = new Date().getFullYear() - 1996;
  environment = environment.assets;

  carouselItems = [{
    background: this.environment + 'products/portal/img/landing/slide0.jpg',
    img: this.environment + 'shared/img/product-logos/png/opsbadge-tagline.png',
    tagline: `Celebrating <b>${this.OpsAnniversary} Years</b> of Service and Innovation.`,
    button: [{
      name: 'Learn More',
      path: ['/benefits']
    }]
    },
    {
    background: this.environment + 'products/portal/img/landing/slide1.jpg',
    img: this.environment + 'shared/img/product-logos/png/trax-oec.png',
    tagline: `The Industry's #1 parts procurement tool.`,
    button: [{
      name: 'Learn More',
      path: ['/product', 'trax']
    }]
    },
    {
    background: this.environment + 'products/portal/img/landing/slide2.jpg',
    img: this.environment + 'shared/img/product-logos/png/deliverytrax-oec.png',
    tagline: `Build, track and analyze your entire logistics operation.`,
    button: [{
      name: 'Learn More ',
      path: ['/product', 'deliverytrax']
    }]
    },
    {
    background: this.environment + 'products/portal/img/landing/slide3.jpg',
    img: this.environment + 'shared/img/product-logos/png/valutrax-oec.png',
    tagline: `The only way to get the right price. Right away.`,
    button: [{
      name: 'Learn More',
      path: ['/product', 'valutrax']
    }]
    },
    {
    background: this.environment + 'products/portal/img/landing/slide4.jpg',
    img: this.environment + 'shared/img/product-logos/png/logistix-oec.png',
    tagline: `It's a whole new way to win.`,
    button: [{
      name: 'Learn More',
      path: ['/product', 'logistix']
    }]
    }
  ];

  constructor(private eventEmitService: EventEmitService) { }

  ngOnInit() {
  }

  redirect(path: string[]) {
    this.eventEmitService.emitGenericEvent(new GenericEvent(GENERIC_EVENT_TYPE.UPDATE_PRODUCT, path[1]));
  }

}
