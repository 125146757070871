import { EventEmitService, GenericEvent, GENERIC_EVENT_TYPE } from './../../shared/services/event-emit.service';
import { Router } from '@angular/router';
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  @Input() showLogin: boolean;
  @Output() showLoginChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  environment = environment.assets;

  aboutItems = [
    {
      title: 'About',
      subtitle: 'Our History & Foundation',
      path: ['/about']
    },
    {
      title: 'Career',
      subtitle: 'Join Our Team',
      path: ['/careers']
    },
    {
      title: 'Contact Us',
      subtitle: 'Reach Out to Us',
      path: ['/contactus']
    },
  ];

  Solutions = [
    {
      section: 'Parts Procurement',
      products: [
        {
          name: 'OPSTrax for Collision Parts',
          description: 'Parts Management & Shop/Supplier CRM tool',
          path: ['/product', 'trax']
        },
        {
          name: 'OPSTrax for Mechanical Parts',
          description: 'Parts Management & Shop/Supplier CRM Tool for Mechanical Parts',
          path: ['/product', 'mechanical']
        },
        {
          name: 'ValuTrax',
          description: 'Conquest Claims Management & Incentive Delivery System',
          path: ['/product', 'valutrax']
        }
      ]
    },
    {
      section: 'Logistics & Delivery',
      products: [
        {
          name: 'DeliveryTrax',
          description: 'Route & Driver Management System',
          path: ['/product', 'deliverytrax']
        },
        {
          name: 'TraxDrive',
          description: 'GPS & Parts Delivery App for Drivers',
          path: ['/product', 'traxdrive']
        },
        {
          name: 'OPSLogistix',
          description: 'Gained Production Delivery System',
          path: ['/product', 'logistix']
        },
        {
          name: 'BackTrax',
          description: 'Electronic Parts Returns',
          path: ['/product', 'backtrax']
        },
      ]
    },
    {
      section: 'Audit & Analytics',
      products: [
        {
          name: 'TraxScan',
          description: 'Parts Audit & Scanning System',
          path: ['/product', 'traxscan']
        },
      ]
    },
  ];


  constructor(
    private eventEmitService: EventEmitService,
    private router: Router) { }

  redirect(path: string[]) {
    this.eventEmitService.emitGenericEvent(new GenericEvent(GENERIC_EVENT_TYPE.UPDATE_PRODUCT, path[1]));
  }

  ngOnInit() {
  }

  isHomeRoute() {
    return this.router.url === '/ops';
  }

  setLoginNavigation() {
    if (this.router.url === '/ops') {
      this.showLoginChange.emit(!this.showLogin);
    } else {
      this.router.navigate(['/ops']);
    }
  }

}
