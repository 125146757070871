<app-navigation></app-navigation>
<!-- for sections with fixed background-->
<div id="about">

  <div class="history-ops">
    <div class="img-content left parallax"></div>
    <div class="container position-right">
      <div class="text-content right">
        <div class="wrapper">
          <div class="positioner">
            <div class="content-about--ops text-left">
              <div class="content-title plr-15  mt-md-5">
                <h3 class="font-uppercase font-bold white-text">ABOUT OPS</h3>
                <div class="line-left orange-bg"></div>
              </div>
              <!-- End-->
              <p class="font-grey">
                Our sole vision is to improve the parts supply-chain for collision repair shops and parts suppliers,
                allowing a consistent and accurate flow of parts so shops can get customers back on the road. We pride
                ourselves in being the industry leader in creating efficiencies and breaking down barriers in the parts
                procurement process.
                <br>
                <br>
                We push the limits of productivity and innovation. Our technology is helping our industry to evolve,
                empowering repairers and suppliers to work anywhere and at any time.
              </p>
              <div class="content-title plr-15 mt-md-5">
                <h3 class="font-uppercase font-bold white-text">OPS COMPANY <span class="orange-text">HISTORY</span>
                </h3>
                <div class="line-left orange-bg"></div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="history-item">
                    <i class="fa-map-pin fa"></i>
                    <div class="history-text">
                      <h4 class="titles">BEGINNINGS &nbsp; <span class="orange-text">|</span> &nbsp; 1996</h4>
                      <p class="font-grey">OPS began in Dallas-Fort Worth in the summer of 1996. Founders Nick
                        Bossinakis and Sib Bahjat launched a solutions company that sought to provide marketing, support
                        and safety certification training to collision repair shops and automotive parts suppliers. It
                        was in these early years that OPS was introduced to the challenges of automotive parts
                        procurement.
                      </p>
                      <p class="font-grey">Meetings and team-building were a challenge in the early days, as meetups
                        were usually at a local coffee shop to review accounts and strategies that would enhance the
                        business. Through hard work, long work days and meeting the needs of its customers, OPS began to
                        build the loyalty and trust of its partners, marking the beginnings of the OPS network.</p>
                    </div>
                  </div>
                  <div class="history-item  mt-md-5">
                    <i class="fa-send-o fa"></i>
                    <div class="history-text">
                      <h4 class="titles">OPS Logistix &nbsp; <span class="orange-text">|</span> &nbsp; 1998</h4>
                      <p class="font-grey">In 1998, OPS launched the OPSLogistix service, allowing shops to gain
                        additional hours in production by guaranteeing parts deliveries by 6:00 AM. Suppliers were also
                        able to off-set costs and build value for their customers by adding additional legs of delivery.
                        The popularity and effectiveness of this service greatly impacted the efficiency of shops and
                        propelled OPS from a company working within a metroplex, to the entire state of Texas.</p>
                    </div>
                  </div>
                  <div class="history-item  mt-md-5">
                    <i class="fa-cog fa"></i>
                    <div class="history-text">
                      <h4 class="titles">OPS Technologies &nbsp; <span class="orange-text">|</span> &nbsp; 2004</h4>
                      <p class="font-grey">After having been entrenched in the day-to-day support of shops and parts
                        suppliers for nine years, OPS had built a unique expertise and found opportunity to advance the
                        industry. Through its experience of servicing both shops and suppliers, OPS saw the need for
                        stronger accountability in the ordering process. It was identified that the industry needed a
                        central tool to manage all procurement capabilities with a strong focus on simplicity and
                        transparency. In the early morning hours, CEO Nick Bossinakis called a meeting that brought the
                        entire OPS team together to ink the early builds of what would become OPSTrax.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="timeline-ops">
    <div class="img-content right"></div>
    <div class="container position-left">
      <div class="text-content left">
        <div class="wrapper">
          <div class="positioner">
            <div class="content-about--ops text-left">
              <div class="content-title plr-15 mt-md-5">
                <h3 class="font-uppercase font-bold white-text">OPS TECHNOLOGIES <span
                    class="orange-text">TIMELINE</span></h3>
                <div class="line-left orange-bg"></div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="history-item">
                    <i class="fa-laptop fa"></i>
                    <div class="history-text">
                      <h4 class="titles">OPSTRAX &nbsp; <span class="orange-text">|</span> &nbsp; 2005</h4>
                      <p class="font-grey">
                        After extensive research and development, OPSTrax launched during the Summer of 2005, and would
                        soon transform the way shops and suppliers do business. OPSTrax would prove to power the overall
                        expansion of OPS’ products and services throughout the rest of the United States.
                      </p>
                    </div>
                  </div>
                  <div class="history-item  mt-md-5">
                    <i class="fa-map-o fa"></i>
                    <div class="history-text">
                      <h4 class="titles">DELIVERYTRAX &nbsp; <span class="orange-text">|</span> &nbsp; 2007</h4>
                      <p class="font-grey">While OPSTrax focused on bridging the gaps in parts procurement, OPS sought
                        to address inefficiencies within the parts delivery and logistics realm. DeliveryTrax was
                        developed and released in the Fall of 2007 allowing suppliers to manage their routes, drivers,
                        and logistics operations. DeliveryTrax addressed the needs of a parts warehouse to fully manage
                        and allow dispatchers, drivers, pullers and sales staff to communicate through one central
                        interface.</p>
                    </div>
                  </div>
                  <div class="history-item  mt-md-5">
                    <i class="fa-money fa"></i>
                    <div class="history-text">
                      <h4 class="titles">VALUTRAX &nbsp; <span class="orange-text">|</span> &nbsp; 2010</h4>
                      <p class="font-grey">From marketing, processing and auditing, ValuTrax was developed and released
                        in the Fall of 2010 as a total turn-key solution to provide manufacturers, suppliers, and parts
                        distributors the ability to administer and implement their parts incentives and deals across the
                        OPS network.</p>
                    </div>
                  </div>
                  <div class="history-item  mt-md-5">
                    <i class="fa-comments-o fa"></i>
                    <div class="history-text">
                      <h4 class="titles">TRAXPOD &nbsp; <span class="orange-text">|</span> &nbsp; 2015</h4>
                      <p class="font-grey">The TraxPod notification tool was released as a streamlined version of
                        OPSTrax. Throughout the parts procurement cycle, TraxPod's event notification system sends
                        electronic notifications to shops regarding deliveries, order shipments, deals, and order-status
                        related content.</p>
                    </div>
                  </div>
                  <div class="history-item  mt-md-5">
                    <i class="fa-map-signs fa"></i>
                    <div class="history-text">
                      <h4 class="titles">TODAY &nbsp; <span class="orange-text">|</span> &nbsp; AND BEYOND</h4>
                      <p class="font-grey">OPS is in the position to further push the boundaries on efficiency and gauge
                        opportunities to reform the automotive repair industry. With a wealth of partners, loyal
                        customers and an exceptional staff, OPS looks to further expand its OPSLogistix foot print and
                        further proliferate its tools and best practices.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="about-container">
    <div class="justify-content-md-center">
      <div>
        <div class="col col-lg-12">
          <div class="content-title plr-15">
          <h3 class="font-uppercase font-bold">OUR LOYAL CUSTOMERS</h3>
            <div class="line-left orange-bg"></div>
          </div>
          <div class="row">
            <div class="col col-lg-12 text-center">
              <img [src]="environment + 'products/portal/img/client-logos/asbury_logo.jpg'" style="padding: 15px"
                width="15%" alt="Asbury Automotive Group">
              <img [src]="environment + 'products/portal/img/client-logos/autonation-logo.jpeg'"
                style="padding: 15px" width="15%" alt="AutoNation">
              <!--<img [src]="environment + 'products/portal/img/client-logos/berkshire_logo.jpg'"
                style="padding: 15px" width="15%" alt="Berkshire Hathaway Automotive">-->
              <img [src]="environment + 'shared/img/make-logos/bmw.jpg'" style="padding: 15px" width="6%"
                alt="BMW">
              <img [src]="environment + 'products/portal/img/client-logos/caliber_logo.jpg'"
                style="padding: 15px" width="15%" alt="Caliber Collision">
              <img [src]="environment + 'shared/img/make-logos/fca.jpg'" style="padding: 5px" width="130px"
                alt="Fiat Chrysler Automobiles">
              <img [src]="environment + 'products/portal/img/client-logos/ford_logo.jpg'" style="padding: 15px"
                width="15%" alt="Ford Motor Company">
            </div>
          </div>
          <div class="row">
            <div class="col col-lg-12 text-center">
              <img [src]="environment + 'products/portal/img/client-logos/gerber_logo.jpg'" style="padding: 15px"
                width="15%" alt="Gerber Collision and Glass">
              <img [src]="environment + 'shared/img/make-logos/gmc-ext.jpg'" style="padding: 15px" width="15%"
                alt="GM Genuine Parts">
              <img [src]="environment + 'products/portal/img/client-logos/group_one_logo.jpg'"
                style="padding: 15px" width="15%" alt="Group 1 Automotive">
              <img [src]="environment + 'products/portal/img/client-logos/subaru_logo.jpg'"
                style="padding: 15px" width="15%" alt="Hyundai">
              <img [src]="environment + 'products/portal/img/client-logos/infiniti_logo.jpg'"
                style="padding: 15px" width="15%" alt="Infiniti">
              <img [src]="environment + 'products/portal/img/client-logos/lexus_logo.jpg'" style="padding: 15px"
                width="15%" alt="Lexus">
              <img [src]="environment + 'shared/img/make-logos/mercedes.jpg'" style="padding: 15px" width="7.5%"
                alt="Mercedes Benz">
            </div>
          </div>
          <div class="row">
            <div class="col col-lg-12 text-center">
              <img [src]="environment + 'shared/img/make-logos/nissan.jpg'" style="padding: 15px" width="8%"
                alt="BMW">
              <img [src]="environment + 'products/portal/img/client-logos/penske_auto_logo.jpg'"
                style="padding: 15px" width="15%" alt="Penske Automotive">
              <img [src]="environment + 'products/portal/img/client-logos/crash-champions_logo.jpg'"
                style="padding: 15px" width="15%" alt="Crash Champions logo">
              <img [src]="environment + 'products/portal/img/client-logos/joe-hudson_logo.jpg'"
                style="padding: 15px" width="15%" alt="Joe Hudson logo">
              <img [src]="environment + 'products/portal/img/client-logos/sonic_auto_logo.jpg'"
                style="padding: 15px" width="15%" alt="Sonic Automotive">
              <img [src]="environment + 'products/portal/img/client-logos/toyota_logo.jpg'" style="padding: 15px"
                width="15%" alt="Toyota">
              <img [src]="environment + 'products/portal/img/client-logos/volkswagen_logo.jpg'"
                style="padding: 15px" width="15%" alt="Volkswagen">
            </div>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12 mt48">
            <div class="content-title plr-15">
              <h3 class="font-uppercase font-bold">SERVICES WE PROVIDE</h3>
              <div class="line-left orange-bg"></div>
            </div>
            <div class="row services-provide">
              <div class="col-sm-6 col-md-3">
                <ul>
                  <li><span>Parts Management</span></li>
                  <li><span>Customer Relations</span></li>
                  <li><span>Field Training</span></li>
                  <li><span>Technical Support</span></li>
                </ul>
              </div>
              <div class="col-sm-6 col-md-3">
                <ul>
                  <li><span>Custom Workflows</span></li>
                  <li><span>Research &amp; Development</span></li>
                  <li><span>Data &amp; Analytics Research</span></li>
                  <li><span>Integration Consulting</span></li>
                </ul>
              </div>
              <div class="col-sm-6 col-md-3">
                <ul>
                  <li><span>Robust Logistics Models</span></li>
                  <li><span>Route &amp; Driver Management</span></li>
                  <li><span>Parts Auditing Systems</span></li>
                  <li><span>User Adoption Strategies</span></li>
                </ul>
              </div>
              <div class="col-sm-6 col-md-3">
                <ul>
                  <li><span>Vendor &amp; Supplier Relations</span></li>
                  <li><span>Manufacturer Tools</span></li>
                  <li><span>Market Trends &amp; Analysis</span></li>
                  <li><span>Parts Distribution</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
