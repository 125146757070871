<footer>
  <div class="prefooter">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-6">
          <h3>OVERALL <span>PARTS</span> SOLUTIONS</h3>
          <p>Join the nation's largest network of Shops and Suppliers today!</p>

          <div class="links">
            <ul>
              <li [routerLink]="'/benefits'">Benefits of OPS</li>
              <li [routerLink]="'/about'">About</li>
              <li><a [routerLink]="['/product','trax']" (click)="redirect(['/product','trax'])">Solutions</a></li>
              <li [routerLink]="'/news-and-media'">News &amp; Media</li>
              <li><a href="https://register.opstrax.com/register.html" target="_blank">Register</a></li>
              <!-- <li [routerLink]="'/support'">Help &amp; Support</li> -->
              <li><a [routerLink]="['/support']">Help &amp; Support</a></li>
              <li><a [routerLink]="['/privacy-policy']">Our Privacy Policy</a></li>
              <li><a href="javascript:void(0)" (click)="ccpaPolicy()">CCPA Policy</a></li>
            </ul>
          </div>

        </div>
        <div class="col-md-3 col-sm-6">
          <h3>RESOURCES</h3>
          <h4 class="ops-m-t">TRAINING SERVICES</h4>
          <p>OPS provides live assisted training for your business.<br>
            <a [routerLink]="['/support']">Inquire about how to get an OPS team member to help you.</a>
          </p>
          <hr>
          <h4>DEDICATED SUPPORT</h4>
          <p>The OPS Support team is available Monday through Friday to assist you with any technical support needs.</p>
        </div>
        <div class="col-md-3 col-sm-6">
          <h3>CONTACT US</h3>

          <p class="ops-m-t">
            If you are interested in our solutions or want to start your subscription, please get in touch with us!
          </p>

          <hr>
          <h4><span>CALL US!<br>877-USE-TRAX</span></h4>
          <p>(877) 873-8729</p>

          <div class="links">
            <ul>
              <li [routerLink]="'/contactus'">Contact Us</li>
              <li><a href="https://register.opstrax.com/register.html" target="_blank">Register</a></li>
              <li><a [routerLink]="['/support']">Support</a></li>
              <!-- <li [routerLink]="'/support'">Support</li> -->
            </ul>
          </div>
        </div>
        <div class="col-md-3 col-sm-6">
          <h3>SOCIAL</h3>

          <p class="ops-m-t">
            Talk to us on any of our social connections.
          </p>

          <div class="social">
            <a target="_blank" href="https://www.linkedin.com/company/overall-parts-solutions-ops-/">
              <i class="fa fa-linkedin" aria-hidden="true"></i>
            </a>
          </div>

          <div class="links flex">
            <ul>
              <li><i class="fa fa-map-marker" aria-hidden="true"></i> <a target="_blank"
                  href="https://www.google.com/maps/place/OPSTRAX/@32.7936184,-97.041795,17z/data=!4m5!3m4!1s0x864e812d721439df:0x824a215f2e407b65!8m2!3d32.7929979!4d-97.0402514">
                  1450 Post & Paddock St. Grand Prairie, TX 75050</a></li>
              <li><i class="fa fa-phone" aria-hidden="true"></i> +877-USE-TRAX</li>
              <li>
                <i class="fa fa-envelope" aria-hidden="true"></i>
                <!-- <a href="mailto: info@opstrax.com">info@opstrax.com</a> -->
                <a href="mailto: info@oeconnection.com">info@oeconnection.com</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="postfooter">
    <div class="container">
      <div>
        <span>© {{currentYear}} Overall Parts Solutions</span> All Rights Reserved.
      </div>
    </div>
  </div>
</footer>
