import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EhiService {
  ehiEndpoint = 'oauth2/ehi-contact';
  ehiTokenEndpoint = 'oauth2/ehi-token';
  constructor(private http: HttpClient, ) {

  }

  getContactId(companyId: number, token: string): Observable<IContactIdResponse> {
    const url = `${environment.identityApiBaseUrl}${this.ehiEndpoint}`;
    const body = new HttpParams()
      .set('companyId', companyId.toString())
      .set('token', token);

    return this.http.post<IContactIdResponse>(url, body, this.getOptions());
  }

  getToken(companyId: number, username: string, password: string): Observable<ITokenResponse> {
    const url = `${environment.identityApiBaseUrl}${this.ehiTokenEndpoint}`;
    const body = new HttpParams()
      .set('companyId', companyId.toString())
      .set('username', username)
      .set('password', password);

    return this.http.post<ITokenResponse>(url, body, this.getOptions());
  }

  private getOptions() {
    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    return httpOptions;
  }
}

interface IContactIdResponse {
  contactId: number;
}

interface ITokenResponse {
  contactId: number;
  jwtToken: string;
}
