<div class="delivery-trax">
  <section class="header">
    <div class="top-bar padding-page">
      <div class="logo">
        <img
          [src]="
            environment + 'products/portal/img/dtrax-ad/deliverytrax-logo.svg'
          "
          alt="Deliverytrax logo"
        />
      </div>
      <div class="previews">
        <!-- <a href="#">< Back to Previous</a> -->
        <button class="go-delivery">
          <a
            href="https://dispatch.opstrax.com/traxd/login/auth"
            target="_blank"
            >Go to DeliveryTrax™</a
          >
        </button>
      </div>
    </div>
    <div class="hero-banner text-center">
      <div>
        <h1>
          Talk to an expert and get the help you need, every step of the way…
        </h1>
        <div class="pill">
          DeliveryTrax™ and TraxDrive™ are a part of your current paid
          subscription. Start using it today!
        </div>
        <div class="connect">
          <form
            ngNoForm
            action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
            method="POST"
          >
            <input type="hidden" name="oid" value="00D37000000IOhS" />
            <input type="hidden" name="retURL" value="{{ confirmationUrl }}" />
            <input
              type="hidden"
              id="lead_source"
              name="lead_source"
              value="DeliveryTrax landing Form"
            />
            <input
              type="text"
              id="email"
              maxlength="80"
              name="email"
              class="search-box"
              placeholder="Provide your email here to connect with an expert…"
            />
            <input class="search-button" type="submit" value="Submit" />
          </form>
        </div>
      </div>
      <img
        class="mobile-pic"
        [src]="environment + 'products/portal/img/dtrax-ad/hero-banner@2x.png'"
        alt="DeliveryTrax"
      />
    </div>
    <div class="tagline">
      <p class="padding-page">
        DeliveryTrax™ integrates into your sales and customer relations
        workflow, making one happy ecosystem!
      </p>
    </div>
  </section>
  <section class="feature pic-left padding-page">
    <img
      [src]="environment + 'products/portal/img/dtrax-ad/feature1@2x.png'"
      alt="Deliverytrax feature"
    />
    <div class="content">
      <h2>
        <span class="orange">DeliveryTrax™ is integrated into OPSTrax™! </span
        >Access orders, customer messages and delivery information from a single
        source.
      </h2>
      <p>
        DeliveryTrax™ is another branch of the OPS ecosystem allowing you to
        fully integrate order and delivery processes into one single platform.
      </p>
    </div>
  </section>
  <section class="feature pic-right padding-page">
    <div class="content">
      <h2>
        Manage <span class="orange">everything</span> in one easy to use online
        workspace
      </h2>
      <p>
        DeliveryTrax™ is an industry leading technology that enhances the
        planning, tracking and monitoring of parts deliveries.
      </p>
    </div>
    <img
      [src]="environment + 'products/portal/img/dtrax-ad/feature2@2x.png'"
      alt="Deliverytrax feature"
    />
  </section>
  <section class="feature pic-left padding-page">
    <img
      [src]="environment + 'products/portal/img/dtrax-ad/feature3@2x.png'"
      alt="Deliverytrax feature"
    />
    <div class="content">
      <h2>
        Here are just a few benefits that make us
        <span class="orange">better than the rest</span>
      </h2>
      <p>
        Streamline your entire process and manage your day-to-day operations
        with these unique to industry features.
      </p>
    </div>
  </section>
  <section class="feature pic-right padding-page">
    <div class="content">
      <h2>
        <span class="orange">Empower your staff</span> with online training and
        Expert Q&A
      </h2>
      <p>
        Get ease of mind knowing you can train new staff or provide refreshers
        to your team at a moment’s notice with our live webinar sessions.
      </p>
    </div>
    <div class="webinars">
      <div class="box">
        <h5>Live Webinar Training</h5>
        <div class="item">
          <h4>
            <a
              href="https://attendee.gotowebinar.com/rt/211898284430887169"
              target="_blank"
              >Introduction/Overview <span>(20 Mins)</span></a
            >
          </h4>
          <p>High level demo of all delivery related software.</p>
        </div>
        <div class="item">
          <h4>
            <a
              href="https://attendee.gotowebinar.com/rt/7145548355556326668"
              target="_blank"
              >Detailed Training-DeliveryTrax (With Set Up)
              <span>(60 Mins)</span></a
            >
          </h4>
          <p>
            VERY detailed training for how to set up and maintain DeliveryTrax
            and common dispatching practices.
          </p>
        </div>
        <div class="item">
          <h4>
            <a
              href="https://attendee.gotowebinar.com/rt/2697796225685594639"
              target="_blank"
              >Detailed Training-DeliveryTrax Dispatching (No Set Up)
              <span>(40 Mins)</span></a
            >
          </h4>
          <p>
            VERY detailed training on dispatching with DeliveryTrax once the
            dispatch has been set up.
          </p>
        </div>
        <div class="item">
          <h4>
            <a
              href="https://attendee.gotowebinar.com/rt/5560935492203963138"
              target="_blank"
              >Detailed Training-TraxDrive <span>(30 Mins)</span></a
            >
          </h4>
          <p>
            Detailed Training on TraxDrive. Includes instructions on
            installation and usage from both the dispatcher and driver’s
            perspective.
          </p>
        </div>

      </div>
      <div class="bg-feature"></div>
    </div>
  </section>
  <section class="feature pic-left padding-page">
    <img
      [src]="environment + 'products/portal/img/dtrax-ad/feature5@2x.png'"
      alt="Deliverytrax feature"
    />
    <div class="content">
      <h2>Adapt to <span class="orange">COVID-19 challenges</span></h2>
      <p>
        Provide touchless delivery for your clients and customers to stop the
        spread of COVID-19, alert your customers to your delivery with real-time
        notifications.
      </p>
    </div>
  </section>
  <section class="quote">
    <div class="quote-content padding-page">
      <h1>
        Thousands of businesses trust DeliveryTrax™ to manage their operations
        everyday…
      </h1>
    </div>
  </section>
  <section class="testimonials padding-page">
    <div class="card1 text-center">
      <div class="logo">
        <img
          [src]="
            environment + 'products/portal/img/dtrax-ad/testimonials1@2x.png'
          "
          alt="deliverytrax"
        />
      </div>
      <div class="txt">
        <p>
          OPS DeliveryTrax™/TraxDrive™ helped our shipping department by
          organizing our drivers and routes to better suit our customers and to
          help us get parts to them faster. I would recommend OPS™ to anyone
          looking to take their deliveries to the next level.
        </p>
      </div>
      <div class="info">
        <p class="name">John Smith</p>
        <p>Parts Director</p>
        <p>Toyota of Irving</p>
      </div>
    </div>
    <div class="card2 text-center">
      <div class="logo">
        <img
          [src]="
            environment + 'products/portal/img/dtrax-ad/testimonials2@2x.png'
          "
          alt="deliverytrax"
        />
      </div>
      <div class="txt">
        <p>
          DeliveryTrax™ has also been a great tool for our parts specialists
          inside the dealership, allowing them to keep track of their delivery
          from start to finish! When a customer calls to inquire about their
          order, the sales person is able to see delivery eta’s, pictures,
          confirmation signatures, and payment information in real time.
          DeliveryTrax™/TraxDrive™ has been a great addition to our business!
        </p>
      </div>
      <div class="info">
        <p class="name">Eric Towery</p>
        <p>Parts Manager</p>
        <p>Hendrick Automotive</p>
      </div>
    </div>
    <div class="card3 text-center">
      <div class="logo">
        <img
          [src]="
            environment + 'products/portal/img/dtrax-ad/testimonials3@2x.png'
          "
          alt="deliverytrax"
        />
      </div>
      <div class="txt">
        <p>
          This is a great tool for dealerships who want to maximize their time,
          be more efficient and monitor the deliveries as well as proof of
          payments. Thank you for suppling us with such a great software
          solution for me and my customers!
        </p>
      </div>
      <div class="info">
        <p class="name">Jamey Warneke</p>
        <p>Parts Director</p>
        <p>Autobahn Fort Worth</p>
      </div>
    </div>
  </section>
</div>

<div class="confirmation-modal" *ngIf="status == 200">
  <div class="title-header">
    <h6>Thank you for Contacting Us!</h6>
  </div>
  <div class="content">
    <div class="check">
      <svg
        width="100"
        height="100"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg"
      >
        <g>
          <g id="svg_1">
            <path
              id="svg_2"
              class="envelope"
              d="m5.6914,8l44.309,34.465l44.312,-34.465l-88.621,0zm-2.6953,2.9766l0,65.023c0,1.1055 0.89844,2.0039 2.0039,2l39.668,0c-0.42969,-1.7656 -0.66406,-3.6055 -0.66406,-5.5c0,-12.906 10.59,-23.504 23.496,-23.504s23.496,10.598 23.496,23.504c0,1.8945 -0.23438,3.7344 -0.66406,5.5l4.6641,0c1.1055,0 2,-0.89453 2,-2l0,-65.016l-45.766,35.594c-0.72266,0.5625 -1.7344,0.5625 -2.4609,0l-45.77298,-35.601z"
            />
            <path
              id="svg_3"
              class="check"
              d="m67.5,53c-10.746,0 -19.504,8.7578 -19.504,19.5c0,10.746 8.7578,19.496 19.504,19.496s19.504,-8.75 19.504,-19.496c0,-10.742 -8.7578,-19.5 -19.504,-19.5zm8.457,12.984c0.82422,-0.02344 1.582,0.46094 1.9023,1.2188s0.14453,1.6367 -0.44531,2.2109l-10.004,9.9961c-0.77734,0.78125 -2.0469,0.78125 -2.8281,0l-4.9961,-4.9961c-0.39453,-0.37109 -0.625,-0.88672 -0.63281,-1.4258c-0.00781,-0.54297 0.20312,-1.0664 0.58594,-1.4492c0.38281,-0.38281 0.90625,-0.59375 1.4492,-0.58594c0.53906,0.01172 1.0547,0.23828 1.4258,0.63281l3.5781,3.582l8.5938,-8.5859c0.36328,-0.37109 0.85547,-0.58203 1.3711,-0.59766l0.00008,-0.00001z"
            />
          </g>
        </g>
      </svg>
    </div>
    <h6>
      Thank you for submitting your email. An OPS customer representative will
      respond to you shortly.
    </h6>
  </div>
  <button class="btn btn-primary" (click)="onDismiss()">Close</button>
</div>
<div class="background-modal" *ngIf="status == 200"></div>
<app-footer></app-footer>
