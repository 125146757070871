import { Component, OnInit } from '@angular/core';
// import { INews, NewsService } from 'src/app/shared/services/news.service';
import { NewsService, INews } from '../../../shared/services/news.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-news-content',
  templateUrl: './news-content.component.html',
  styleUrls: ['./news-content.component.scss' , '../news-feed.component.scss']
})

export class NewsContentComponent implements OnInit {
  environment = environment.assets;
  newsName = '';
  news: INews;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private newsService: NewsService) { }

  ngOnInit() {
    window.scrollTo(0, 0);

    this.newsName = this.route.snapshot.paramMap.get('newsName');
    this.newsService.get(this.newsName).then(result => {
      this.news = result;
      if (!this.news) {
         this.router.navigate(['/page-not-found']);
      }
    });
  }


}
