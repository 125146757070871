<app-navigation></app-navigation>
<div class="content-box">
  <div class="container">
    <div class="content-title plr-15">
      <h3 class="font-uppercase font-bold">News &amp; Media</h3>
      <div class="line-left orange-bg"></div>
    </div>
    <div class="content-feed" *ngFor="let n of news | paginate: { itemsPerPage: 4, currentPage: currentPage }">
      <div [ngSwitch]="n.isInternal">


        <div *ngSwitchDefault>
          <div class="change-ratio feed">
            <img class="img-fluid feed" *ngIf="n.srcType == 'photo'" [src]="environment + n.src">
          </div>
          <div *ngIf="n.srcType == 'video'" class="video-container">
            <iframe width="760" height="428" [src]="n.src | safe" frameborder="0" allowfullscreen></iframe>
          </div>
          <div class="title font-bold font-uppercase"><a [routerLink]="n.name">{{n.headline}}</a></div>
          <div class="byline">{{n.byline}}</div>
          <div class="author-box"><a>By {{n.author}}</a>&nbsp; {{n.date | date: 'MMMM dd, yyyy'}}</div>
        </div>

        <div class="external" *ngSwitchCase="false">
          <div class="change-ratio feed" *ngIf="n.srcType == 'photo'">
            <img class="img-fluid feed" [src]="environment + n.src">
          </div>
          <div *ngIf="n.srcType == 'video'" class="video-container">
            <iframe width="760" height="428" [src]="n.src | safe" frameborder="0" allowfullscreen></iframe>
          </div>
          <div class="title font-bold font-uppercase"><a [href]="n.path">{{n.headline}}</a></div>
          <div class="byline">{{n.byline}}</div>
          <div class="author-box"><a>By {{n.author}}</a>&nbsp; {{n.date | date: 'MMMM dd, yyyy'}}</div>
        </div>


      </div>
    </div>
    <div class="text-center">
      <pagination-controls (pageChange)="onPageChange($event)"></pagination-controls>
    </div>

  </div>
</div>
<app-footer></app-footer>
