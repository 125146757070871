import { LoginOpsComponent } from './login/login-ops/login-ops.component';
import { FastsupportComponent } from './features/support/fastsupport/fastsupport.component';
import { AdminPanelLoginComponent } from './login/admin-panel-login/admin-panel-login.component';
import { SubscribeComponent } from './features/subscribe/subscribe.component';
import { EhiLoginComponent } from './login/ehi-login/ehi-login.component';
import { CareerDetailsComponent } from './features/careers/career-details/career-details.component';
import { CareersComponent } from './features/careers/careers.component';
import { ContactusComponent } from './features/contactus/contactus.component';
import { BenefitsComponent } from './features/benefits/benefits.component';
import { PageNotFoundComponent } from './layout/pagenotfound/pagenotfound.component';
import { ProductComponent } from './features/product/product.component';
import { LoginComponent } from './login/app-login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './features/landing/landing.component';
import { NewsFeedComponent } from './features/news-feed/news-feed.component';
import { NewsContentComponent } from './features/news-feed/news/news-content.component';
import { AboutComponent } from './features/about/about.component';
import { SupportComponent } from './features/support/support.component';
import { RegisterComponent } from './features/register/register.component';
import { PrivacyPolicyComponent } from './features/policy/privacy-policy/privacy-policy.component';
import { CcpaPolicyComponent } from './features/policy/ccpa-policy/ccpa-policy.component';
import { SellInformationComponent } from './shared/components/sell-information/sell-information.component';
import { DtraxAdComponent } from './features/dtrax-ad/dtrax-ad.component';
import { QrLeadAdComponent } from './features/qr-lead-ad/qr-lead-ad.component';
import { TraxmobileAdComponent } from './features/traxmobile-ad/traxmobile-ad.component';
import { RedirectComponent } from './login/redirect/redirect.component';
import { Idsrv4LoginComponent } from './login/idsrv4-login/idsrv4-login.component';
import { SigninCallbackComponent } from './login/signin-callback/signin-callback.component';
import { SignoutCallbackComponent } from './login/signout-callback/signout-callback.component';
import { Idsrv4LegacyComponent } from './login/idsrv4-legacy/idsrv4-legacy.component';

const routes: Routes = [
  {
    path: '', redirectTo: '/ops', pathMatch: 'full'
  },
  {
    path: 'ops',
    component: LoginOpsComponent,
    data: {
      title: 'OPS - Overall Parts Solutions',
      description: 'OPS Technologies enhance business profitability, performance and productivity for clients across the nation.',
      keyword: 'opstrax, overall, parts, solutions, bodyshop, car, parts, supplier'
    }
  },
  {
    path: 'old-login',
    component: LandingComponent,
    data: {
      title: 'OPS - Overall Parts Solutions',
      description: 'OPS Technologies enhance business profitability, performance and productivity for clients across the nation.',
      keyword: 'opstrax, overall, parts, solutions, bodyshop, car, parts, supplier'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'OPS - Login',
      description: 'Overall Parts Solution Login Page',
      keyword: 'opstrax, login, log, in, ops, overall, parts, solutions'
    }
  },
  {
    path: 'idsrv4',
    component: Idsrv4LoginComponent,
    data: {
      title: 'OPS - Login',
      description: 'Overall Parts Solution Login Page',
      keyword: 'opstrax, login, log, in, ops, overall, parts, solutions'
    }
  },
  {
    path: 'idsrv4-legacy',
    component: Idsrv4LegacyComponent,
    data: {
      title: 'OPS - Login',
      description: 'Overall Parts Solution Login Page',
      keyword: 'opstrax, login, log, in, ops, overall, parts, solutions'
    }
  },
  {
    path: 'signin-callback',
    component: SigninCallbackComponent,
    data: {
      title: 'OPS - Login',
      description: 'Overall Parts Solution Login Page',
      keyword: 'opstrax, login, log, in, ops, overall, parts, solutions'
    }
  },
  {
    path: 'signout-callback',
    component: SignoutCallbackComponent,
    data: {
      title: 'OPS - Login',
      description: 'Overall Parts Solution Login Page',
      keyword: 'opstrax, login, log, in, ops, overall, parts, solutions'
    }
  },
  {
    path: 'login/:product',
    component: LoginComponent,
    data: {
      title: 'OPS - Login',
      description: 'Overall Parts Solution Login Page',
      keyword: 'opstrax, login, log, in, ops, overall, parts, solutions'
    }
  },
  {
    path: 'ehi-login',
    component: EhiLoginComponent,
    data: {
      title: 'OPS - Login',
      description: 'Overall Parts Solution Login Page',
      keyword: 'opstrax, login, log, in, ops, overall, parts, solutions'
    }
  },
  {
    path: 'adminpanel-login',
    component: AdminPanelLoginComponent,
    data: {
      title: 'OPS - Login',
      description: 'Overall Parts Solution Login Page',
      keyword: 'opstrax, login, log, in, ops, overall, parts, solutions'
    }
  },
  {
    path: 'product/:productName',
    component: ProductComponent,
    data: {
      title: 'OPS - Overall Parts Solutions',
      description: 'OPS Technologies enhance business profitability, performance and productivity for clients across the nation.',
      keyword: 'opstrax, overall, parts, solutions'
    }
  },
  {
    path: 'news-and-media',
    component: NewsFeedComponent,
    data: {
      title: 'OPS - News',
      description: 'Stay updated with the latest OPS News',
      keyword: 'opstrax, contact, us, ops, overall, parts, solutions'
    }
  },
  {
    path: 'news-and-media/:newsName', component: NewsContentComponent
  },
  {
    path: 'benefits',
    component: BenefitsComponent,
    data: {
      title: 'OPS - Benefits',
      description: 'Benefits of Overall Parts Solutions',
      keyword: 'opstrax, benefits, ops, overall, parts, solutions'
    }
  },
  {
    path: 'about',
    component: AboutComponent,
    data: {
      title: 'OPS - About OPS',
      description: 'About Overall Parts Solutions',
      keyword: 'opstrax, about, ops, overall, parts, solutions'
    }
  },
  {
    path: 'contactus',
    component: ContactusComponent,
    data: {
      title: 'OPS - About OPS',
      description: 'Get it touch with our specialists',
      keyword: 'opstrax, contact, us, ops, overall, parts, solutions'
    }
  },
  {
    path: 'support',
    component: SupportComponent,
    data: {
      title: 'OPS - Support',
      description: 'Get it touch with our specialists',
      keyword: 'opstrax, support, help, ops, overall, parts, solutions'
    }
  },
  {
    path: 'remote-connect',
    component: FastsupportComponent,
    data: {
      title: 'OPS - Support',
      description: 'Get it touch with our specialists',
      keyword: 'opstrax, support, help, ops, overall, parts, solutions'
    }
  },
  {
    path: 'support/:status',
    component: SupportComponent,
    data: {
      title: 'OPS - Support',
      description: 'Get it touch with our specialists',
      keyword: 'opstrax, support, help, ops, overall, parts, solutions'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'OPS - Register',
      description: 'Get it touch with our specialists',
      keyword: 'opstrax, register, registration, ops, overall, parts, solutions'
    }
  },
  {
    path: 'subscribe',
    component: SubscribeComponent,
    data: {
      title: 'OPS - Subscribe',
      description: 'Get it touch with our specialists',
      keyword: 'opstrax, register, subscribe, ops, overall, parts, solutions'
    }
  },
  {
    path: 'careers',
    component: CareersComponent,
    data: {
      title: 'OPS - Careers',
      description: 'Start a career with Overall Parts Solution',
      keyword: 'opstrax, career, jobs, ops, overall, parts, solutions'
    }
  },
  {
    path: 'careers/:anchor',
    component: CareersComponent,
    data: {
      title: 'OPS - Careers',
      description: 'Start a career with Overall Parts Solution',
      keyword: 'opstrax, career, jobs, ops, overall, parts, solutions'
    }
  },
  {
    path: 'career/:careerName',
    component: CareerDetailsComponent,
    data: {
      title: 'OPS - Careers',
      description: 'Start a career with Overall Parts Solution',
      keyword: 'opstrax, career, jobs, ops, overall, parts, solutions'
    }
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: {
      title: 'Our Privacy Policy',
      description: 'Privacy Policy describes the practices and measures taken by Overall Parts Solutions',
      keyword: 'opstrax, policy, ccpa, ops, overall, parts, solutions'
    }
  },
  {
    path: 'privacy-policy/:anchor',
    component: PrivacyPolicyComponent,
    data: {
      title: 'Our Privacy Policy',
      description: 'Privacy Policy describes the practices and measures taken by Overall Parts Solutions',
      keyword: 'opstrax, policy, ccpa, ops, overall, parts, solutions'
    }
  },
  {
    path: 'ccpa-policy',
    component: CcpaPolicyComponent,
    data: {
      title: 'California Consumer Privacy Act and OPS',
      description: 'Regulation governed by the state of California which supports California residents.',
      keyword: 'opstrax, policy, ccpa, ops, overall, parts, solutions'
    }
  },
  {
    path: 'sell-information',
    component: SellInformationComponent,
    data: {
      title: 'Do not sell my information',
      description: 'Privacy Policy describes the practices and measures taken by Overall Parts Solutions',
      keyword: 'opstrax, policy, ccpa, ops, overall, parts, solutions'
    }
  },
  {
    path: 'deliverytrax-ad',
    component: DtraxAdComponent,
    data: {
      title: 'DeliveryTrax product page',
      description: 'DeliveryTrax and TraxDrive are a part of your current paid subscription. Start using it today!',
      keyword: 'opstrax, policy, ccpa, ops, overall, parts, solutions'
    }
  },
  {
    path: 'traxmobile-ad',
    component: TraxmobileAdComponent,
    data: {
      title: 'Traxmobile product page',
      description: 'We have expanded our industry-leading OPS Technology platform',
      keyword: 'opstrax, policy, ccpa, ops, overall, parts, solutions'
    }
  },
  {
    path: 'qr-trax-mechanical',
    component: QrLeadAdComponent,
    data: {
      title: 'Trax Mechanical QR page',
      description: 'All-New TraxDrive that will Improve your overall Delivery Experience!',
      keyword: 'opstrax, policy, ccpa, ops, overall, parts, solutions'
    }
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
    data: {
      title: 'OPS - Page Not Found',
      description: 'The requested page was not found',
      keyword: 'opstrax, 404, not, found, ops, overall, parts, solutions'
    }
  },
  {
    path: 'redirect',
    component: RedirectComponent
  },
  { path: '**', redirectTo: '/page-not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
