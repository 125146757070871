<div id="compact-login" >

  <div class="texture"></div>

  <div class="login-content">
    <div class="ops-logo">
      <img draggable="false" [src]="environment + 'shared/img/product-logos/png/opsbadge.png'" alt="OPS">
      <div>Technologies</div>
    </div>
    <form (ngSubmit)="signin()" name="loginForm2" method="post" [action]="redirectUrl">
      <input type="text" name="UserName" id="UserName" [(ngModel)]="username" placeholder="Enter Username" />
      <input type="password" name="Password" id="Password" [(ngModel)]="password" style="-webkit-text-security: circle;"
        placeholder="Enter Password" />

      <p style="color:red" *ngIf="showError">*{{error}}</p>

      <!-- <label class="input">
        <input name="rememberMe" type="checkbox" class="input__field">
        <span class="input__text checkbox"> Remember Me</span>
      </label> -->

      <input type="submit" value="LOGIN">

      <a class="forgot-password" href="https://admin.opstrax.com/admind/forgotLogin">Forgot Password?</a>

    </form>
    <div class="social-media">
      <!-- <a href="https://twitter.com/opstrax" target="_blank" class="sm-container">
        <i class="fa fa-twitter"></i>
      </a> -->
      <a href="https://www.linkedin.com/company/overall-parts-solutions-ops-/about/" target="_blank"
        class="sm-container">
        <i class="fa fa-linkedin"></i>
      </a>
      <!-- <a href="https://www.youtube.com/user/OPSTRAX1" target="_blank" class="sm-container">
        <i class="fa fa-youtube-play"></i>
      </a> -->
    </div>
  </div>

  <div class="ads">
    <app-ads></app-ads>
  </div>

</div>
