import { User } from './../../shared/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CommonApiService } from 'src/app/shared/services/common-api.service';

@Component({
  selector: 'app-admin-panel-login',
  templateUrl: './admin-panel-login.component.html',
  styleUrls: ['./admin-panel-login.component.scss', './../ehi-login/ehi-login.component.scss']
})
export class AdminPanelLoginComponent implements OnInit {
  isLoading = false;
  showError = false;
  showForm = false;
  error = '';
  token = '';
  currentYear: number = new Date().getFullYear();
  environment = environment.assets;

  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private commonApiService: CommonApiService
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      const token = params.token;
      if (token) {
        this.loginAndRedirect(token);
      } else {
        this.showForm = true;
        // this.router.navigate(['page-not-found']);
      }

    });
  }

  loginAndRedirect(token: string) {
    this.isLoading = true;
    this.authService.adminPanelLogin(token ? token : this.token).subscribe(res => {
      const user = this.authService.getCurrentUser();
      this.isLoading = false;
      if (res.contactType === 1) {
        this.isLoading = false;
        this.commonApiService.GetNavigationBarLinks({ id: 27, encrypted: true }).subscribe(result => {
          result.map(x => {
            if (x.app_id === 27) {
              window.location.href = x.url + 'clientid-login?jwt=' + res.access_token;
            }
          });
        });
      }
      if (res.contactType === 2) {
        this.isLoading = false;
        this.commonApiService.GetNavigationBarLinks({ id: 25, encrypted: true }).subscribe(result => {
          result.map(x => {
            if (x.app_id === 25) {
              window.location.href = x.url + 'clientid-login?jwt=' + res.access_token;
            }
          });
        });
      }
    },
      error => {
        this.error = 'Invalid token';
        this.isLoading = false;
        this.showError = true;
      });
  }



}


