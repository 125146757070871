import { ActivatedRoute } from '@angular/router';
import { CommonApiService } from './../../shared/services/common-api.service';
import { AuthService } from './../../shared/services/auth.service';
import { LoginComponent } from 'src/app/login/app-login/login.component';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-redirect',
  template: '',
  styleUrls: []
})
export class RedirectComponent extends LoginComponent implements OnInit  {



  constructor(
    public authService: AuthService,
    public commonsApiService: CommonApiService,
    public route: ActivatedRoute
  ) {
    super(authService, commonsApiService);
    this.route.queryParams.subscribe(params => {
      this.username = params.username;
      this.password = params.password;
      this.ronumber = params.ronumber;
      this.signin();
    });
  }

  ngOnInit(): void {
  }

}
