<div id="opsHome" class="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators">
    <li *ngFor="let items of carouselItems; let i = index" data-target="#opsHome" attr.data-slide-to="{{i}}" [ngClass]="(i === 0)? 'active':''"></li>
  </ol>
  <div class="carousel-inner">
      <div *ngFor="let items of carouselItems; let i = index" class="carousel-item" [ngClass]="{active: i === 0}">
        <div class="ops" [ngStyle]="{'background-image': 'url('+ items.background +')'}">
          <div class="content">
            <img draggable="false" class="logo" [src]="items.img">
            <h3 class="tagline" [innerHTML]="items.tagline"></h3>
            <a class="learn-more" *ngFor="let btn of items.button" (click)="redirect(btn.path)" [routerLink]="btn.path">{{btn.name}}</a>
          </div>
          <div class="gradient-background"></div>
        </div>
      </div>
  </div>
</div>
