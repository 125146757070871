<div id="cookieElement" class="ops-modal slide-in-top">
  <div class="ops-modal-body">
      <div class="content">
        <h4>Cookies</h4>
        <p>
          This site uses cookies to provide you with a great user experience.  By using our site, you accept our <a href="javascript:void(0)" (click)="cookiePolicy()">use of cookies</a>, as well as to our <a [routerLink]="['/privacy-policy']">Privacy Policy</a>.
        </p>
        <div class="text-right">
          <a class="btn__close" (click)="btnClose()">Continue</a>
        </div>
      </div>
  </div>
</div>
