import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  environment = environment.assets;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    // nav: true,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    items: 3,
  };

  supplierTechnologies = [
    {
      name: 'Trax',
      logo: this.environment + 'shared/img/product-logos/png/trax.png',
      path: '#',
      class: 'trax-size'
    },
    {
      name: 'Valutrax',
      logo: this.environment + 'shared/img/product-logos/png/valutrax.png',
      path: '#',
      class: 'valutrax-size'
    },
    {
      name: 'Backtrax',
      logo: this.environment + 'shared/img/product-logos/png/backtrax.png',
      path: '#',
      class: 'backtrax-size'
    },
    {
      name: 'DeliveryTrax',
      logo: this.environment + 'shared/img/product-logos/png/deliverytrax.png',
      path: '#',
      class: 'delivery-size'
    },
    {
      name: 'TraxMobile',
      logo: this.environment + 'shared/img/product-logos/png/traxmobile.png',
      path: '#',
      class: 'mobile-size'
    },
    {
      name: 'Logistix',
      logo: this.environment + 'shared/img/product-logos/png/logistix.png',
      path: '#',
      class: 'logistix-size'
    }
  ];

  shopTechnologies = [
    {
      name: 'Trax',
      logo: this.environment + 'shared/img/product-logos/png/trax.png',
      path: '#',
      class: 'trax-size'
    },
    {
      name: 'Backtrax',
      logo: this.environment + 'shared/img/product-logos/png/backtrax.png',
      path: '#',
      class: 'backtrax-size'
    },
    {
      name: 'TraxPost',
      logo: this.environment + 'shared/img/product-logos/png/traxpost.png',
      path: '#',
      class: 'traxpost-size'
    },
    {
      name: 'TraxMobile',
      logo: this.environment + 'shared/img/product-logos/png/traxmobile.png',
      path: '#',
      class: 'mobile-size'
    },
    {
      name: 'Logistix',
      logo: this.environment + 'shared/img/product-logos/png/logistix.png',
      path: '#',
      class: 'logistix-size'
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
