<div id="news" class="container-fluid">
  <div class="news-container">
    <div class="row">

      <div class="col-sm">
        <h5>Latest News &amp; Media</h5>

        <ng-container *ngFor="let n of news; let i = index">
          <div [ngSwitch]="n.isInternal" *ngIf="i < 4">
            <a *ngSwitchCase="false" class="link" [href]="n.path" target="_blank">{{n.headline}}</a>
            <a *ngSwitchDefault class="link" [routerLink]="['/news-and-media',n.name]">{{n.headline}}</a>
          </div>
        </ng-container>

        <a class="button" [routerLink]="['/news-and-media']">See More</a>
      </div>



      <div id="network" class="col-sm">
        <h5>Network</h5>
        <h4>Largest Network of Shops &amp; Suppliers</h4>
        <span>OPS has been the industry leader in parts management solutions for over 20 years. Major Multi-Shop
          Operators and parts suppliers consider OPS' state-of-the-art tools their preferred choice.</span>
        <a class="button" [routerLink]="['/benefits']">Learn More</a>
      </div>

      <div id="demo" class="col-sm">
        <h5>Demo</h5>
        <h4>Find out how OPS Technologies can benefit your business</h4>
        <span>OPS Technologies enhance business profitability, performance and productivity for clients across the
          nation.</span>
        <a class="button" [routerLink]="['/contactus']">See Demo</a>
      </div>
    </div>

  </div>
</div>
