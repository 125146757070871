import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class EventEmitService {

  private eventSource: Subject<GenericEvent> = new Subject<GenericEvent>();
  public events: Observable<GenericEvent> = this.eventSource.asObservable();
  lastProduct: string;

  constructor() { }

  public emitGenericEvent(event: GenericEvent) {
    if (this.eventSource) {
      // Push up a new event
      // tslint:disable-next-line: no-use-before-declare
      if (event.type === GENERIC_EVENT_TYPE.UPDATE_PRODUCT) {
        this.lastProduct = event.value;
      }
      this.eventSource.next(event);
    }
  }
}


export class GenericEvent {
  constructor(public type: GENERIC_EVENT_TYPE, public value: any) { }
}
export enum GENERIC_EVENT_TYPE {
  UPDATE_PRODUCT
}
