import { Component, OnInit } from '@angular/core';
import { LoginComponent } from 'src/app/login/app-login/login.component';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';
import { CommonApiService } from '../../services/common-api.service';

@Component({
  selector: 'app-compact-login-v2',
  templateUrl: './compact-login-v2.component.html',
  styleUrls: ['./compact-login-v2.component.scss']
})
export class CompactLoginV2Component extends LoginComponent {

  constructor(public authService: AuthService,
              public commonsApiService: CommonApiService, ) {
    super(authService, commonsApiService);
  }



}
