import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {
  @Input() product: any;
  @Input() productName: any;
  environment = environment.assets;
  constructor() { }

  ngOnInit() {
  }

}
