import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-qr-lead-ad',
  templateUrl: './qr-lead-ad.component.html',
  styleUrls: ['./qr-lead-ad.component.scss']
})
export class QrLeadAdComponent implements OnInit {

  environment = environment.assets;

  constructor() { }

  ngOnInit() {
  }

}
