<!-- registration-header -->
<div class="page-header box-shadow">
  <div class="container">
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="page-caption">
          <div class="back-web pull-right">
            <a [routerLink]="['/ops']">
              <button class="back-btn"><i class="fa fa-arrow-left"></i>Back to OPS Site</button>
            </a>
          </div>
          <div class="page-title text-left">
            <object style="width:75px;"
              [data]="environment + 'products/registration/landing/img/ops-badge-logo.svg'"
              type="image/svg+xml"></object>
            <div class="border-left-reg text-header">Registration</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /.registration-header-->
<!-- content -->
<div class="card-section">
  <div class="container">
    <div class="card-block bg-white mb30 box-shadow">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <!-- section-title -->
          <div class="section-title mb-0">
            <h3 class="font-bold primary-color">Please Choose Your Type of Company</h3>
          </div>
          <!-- /.section-title -->
          <!-- section-options -->
          <div class="section-options mb-0">

            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="corner">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="442"
                    height="414.078" viewBox="0 0 442 414.078">
                    <defs>
                      <clipPath id="clip-path">
                        <rect id="Rectangle_30" data-name="Rectangle 30" width="442" height="414.078" rx="11"
                          transform="translate(229 230)" fill="#fff" stroke="#e6e6e6" stroke-width="2" />
                      </clipPath>
                    </defs>
                    <g id="Mask_Group_3" data-name="Mask Group 3" transform="translate(-229 -230)"
                      clip-path="url(#clip-path)">
                      <path id="Path_10" data-name="Path 10" d="M1769.24,326.91h210.432L1769.24,475.988Z"
                        transform="translate(-1540 -96.91)" fill="#f5f5f5" />
                    </g>
                  </svg>
                </div>
                <div class="box-part text-center">
                  <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                      <div class="icon-type">
                        <object [data]="environment + 'products/registration/landing/img/shop-icon.svg'"
                          type="image/svg+xml"></object>
                      </div>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12 pl-50">
                      <div class="title">
                        <h3 class="font-bold primary-color text-left">I'm a Shop.</h3>
                      </div>
                      <div class="text text-left">
                        <div class="mb-20">Gain back hours in productivity using America's preferred parts management
                          solution.</div>
                        <ul class="pl-10">
                          <li>Online Ordering & Tracking</li>
                          <li>Instant Messaging</li>
                          <li>Web and iOS/Android Access</li>
                          <li>Free to Subscribe</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="logos-container">
                        <owl-carousel-o [options]="customOptions">
                          <ng-container *ngFor="let shopTechnologie of shopTechnologies; let i = index">
                            <ng-template carouselSlide [id]="shopTechnologie.name">
                              <img class="slide-image" [ngClass]="shopTechnologie.class" [title]="shopTechnologie.name"
                                [src]="shopTechnologie.logo">
                            </ng-template>
                          </ng-container>
                        </owl-carousel-o>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <a [routerLink]="['/subscribe']">
                        <button class="box-shadow mouse-pointer">Register as a
                          Shop</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="corner">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="442"
                    height="414.078" viewBox="0 0 442 414.078">
                    <defs>
                      <clipPath id="clip-path">
                        <rect id="Rectangle_30" data-name="Rectangle 30" width="442" height="414.078" rx="11"
                          transform="translate(229 230)" fill="#fff" stroke="#e6e6e6" stroke-width="2" />
                      </clipPath>
                    </defs>
                    <g id="Mask_Group_3" data-name="Mask Group 3" transform="translate(-229 -230)"
                      clip-path="url(#clip-path)">
                      <path id="Path_10" data-name="Path 10" d="M1769.24,326.91h210.432L1769.24,475.988Z"
                        transform="translate(-1540 -96.91)" fill="#f5f5f5" />
                    </g>
                  </svg>
                </div>
                <div class="box-part text-center">
                  <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                      <div class="icon-type">
                        <object [data]="environment + 'products/registration/landing/img/supplier-logo.svg'"
                          type="image/svg+xml"></object>
                      </div>
                    </div>
                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-12 pl-50">
                      <div class="title">
                        <h3 class="font-bold primary-color text-left">I'm a Parts Supplier.</h3>
                      </div>
                      <div class="text text-left">
                        <div class="mb-20">Access solutions that streamline ordering and delivery processes for your
                          wholesale parts operation.</div>
                        <ul class="pl-10">
                          <li>Order & Conquest Fulfillment</li>
                          <li>Customer Account Management</li>
                          <li>Dispatch & Driver Management</li>
                          <li>Multi-Tiered Subscriptions</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="logos-container">
                        <owl-carousel-o [options]="customOptions">
                          <ng-container *ngFor="let supplierTechnologie of supplierTechnologies; let i = index">
                            <ng-template carouselSlide [id]="supplierTechnologie.name">
                              <img class="slide-image" [ngClass]="supplierTechnologie.class"
                                [title]="supplierTechnologie.name" [src]="supplierTechnologie.logo">
                            </ng-template>
                          </ng-container>
                        </owl-carousel-o>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                      <a href="https://register.opstrax.com/register/dealerRegistration/">
                        <button class="box-shadow mouse-pointer">Register as a Parts Supplier</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <!-- /.section-options -->
        </div>
      </div>
    </div>
  </div>
</div>
<footer class="navbar-fixed-bottom">© 2019 Overall Parts Solutions, All rights reserved.</footer>
