import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';
import urlJoin from 'url-join';
@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss']
})

export class BenefitsComponent implements OnInit {
  // carouselOptions = {
  //   margin: 25,
  //   items: 6,
  //   nav: true,
  //   dots: false,
  //   autoplay: true,
  //   autoplayTimeout: 2000,
  //   loop: true,
  //   autoplayHoverPause: true,
  //   navText: ['<div class="nav-btn prev-slide"></div>', '<div class="nav-btn next-slide"></div>'],
  // };
  environment = environment.assets;

  assetsUrl = {
    beam1: urlJoin(this.environment, 'products/portal/img/benefits/beam1.png'),
    beam2: urlJoin(this.environment, 'products/portal/img/benefits/beam2.png'),
    beam3: urlJoin(this.environment, 'products/portal/img/benefits/beam3.png'),
    beam4: urlJoin(this.environment, 'products/portal/img/benefits/beam4.png'),
    opsBadge: urlJoin(this.environment, 'shared/img/product-logos/png/opsbadge-tagline.png'),
    explore: urlJoin(this.environment, 'products/portal/img/benefits/jango-intro-2.jpg'),
  };

  customOptions: OwlOptions = {
    autoWidth: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    // nav: true,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    items: 6,
  };

  partners = [
    {
      name: 'Asbury',
      logo: urlJoin(this.environment, 'products/portal/img/client-logos/asbury_logo.jpg'),
      path: 'https://www.asburyauto.com/'
    },
    {
      name: 'Autonation',
      logo: urlJoin(this.environment, 'products/portal/img/client-logos/autonation-logo.jpeg'),
      path: 'https://www.autonation.com/'
    },
    {
      name: 'Berkshire',
      logo: urlJoin(this.environment, 'products/portal/img/client-logos/berkshire_logo.jpg'),
      path: 'https://www.berkshirehathawayautomotive.com/'
    },
    {
      name: 'caliber',
      logo: urlJoin(this.environment, 'products/portal/img/client-logos/caliber_logo.jpg'),
      path: '#'
    },
    {
      name: 'Ford',
      logo: urlJoin(this.environment, 'products/portal/img/client-logos/ford_logo.jpg'),
      path: '#'
    },
    {
      name: 'Gerber',
      logo: urlJoin(this.environment, 'products/portal/img/client-logos/gerber_logo.jpg'),
      path: '#'
    },
    {
      name: 'GMC',
      logo: urlJoin(this.environment, 'shared/img/make-logos/gmc-ext.jpg'),
      path: '#'
    },
    {
      name: 'Group-one',
      logo: urlJoin(this.environment, 'products/portal/img/client-logos/group_one_logo.jpg'),
      path: '#'
    },
    {
      name: 'hyundai',
      logo: urlJoin(this.environment, 'products/portal/img/client-logos/hyundai_logo.jpg'),
      path: '#'
    },
    {
      name: 'Subaru',
      logo: urlJoin(this.environment, 'products/portal/img/client-logos/subaru_logo.jpg'),
      path: '#'
    },
    {
      name: 'Infinity',
      logo: urlJoin(this.environment, 'products/portal/img/client-logos/infiniti_logo.jpg'),
      path: '#'
    },
    {
      name: 'Lexus',
      logo: urlJoin(this.environment, 'products/portal/img/client-logos/lexus_logo.jpg'),
      path: '#'
    },
    {
      name: 'Penske',
      logo: urlJoin(this.environment, 'products/portal/img/client-logos/penske_auto_logo.jpg'),
      path: '#'
    },
    {
      name: 'Crash Champion',
      logo: urlJoin(this.environment, 'products/portal/img/client-logos/crash-champions_logo.jpg'),
      path: '#'
    },
    {
      name: 'Joe Hudson',
      logo: urlJoin(this.environment, 'products/portal/img/client-logos/joe-hudson_logo.jpg'),
      path: '#'
    },
    {
      name: 'Sonic',
      logo: urlJoin(this.environment, 'products/portal/img/client-logos/sonic_auto_logo.jpg'),
      path: '#'
    },
    {
      name: 'Toyota',
      logo: urlJoin(this.environment, 'products/portal/img/client-logos/toyota_logo.jpg'),
      path: '#'
    },
    {
      name: 'volkswagen',
      logo: urlJoin(this.environment, 'products/portal/img/client-logos/volkswagen_logo.jpg'),
      path: '#'
    },
    {
      name: 'Mercedes Benz',
      logo: urlJoin(this.environment, 'shared/img/make-logos/mercedes.jpg'),
      path: '#'
    },
    {
      name: 'Nissan',
      logo: urlJoin(this.environment, 'products/portal/img/client-logos/nissan_logo.jpg'),
      path: '#'
    },
    {
      name: 'BMW',
      logo: urlJoin(this.environment, 'shared/img/make-logos/bmw.jpg'),
      path: '#'
    },
    {
      name: 'FCA',
      logo: urlJoin(this.environment, 'shared/img/make-logos/fca.jpg'),
      path: '#'
    },
  ];

  technologies = [
    {
      icon: urlJoin(this.environment, 'products/portal/img/benefits/icon-1.png'),
      logo: urlJoin(this.environment, 'shared/img/product-logos/png/trax-oec.png'),
      path: ['/product', 'trax'],
      description: 'Our comprehensive parts management and shop/supplier CRM tool maximizes production efficiency.'
    },
    {
      icon: urlJoin(this.environment, 'products/portal/img/benefits/icon-2.png'),
      logo: urlJoin(this.environment, 'shared/img/product-logos/png/valutrax-oec.png'),
      path: ['/product', 'valutrax'],
      description: 'An OPSTrax™ companion tool, that gives suppliers the ability to promote incentive pricing.'
    },
    {
      icon: urlJoin(this.environment, 'products/portal/img/benefits/icon-6.png'),
      logo: urlJoin(this.environment, 'shared/img/product-logos/png/logistix-oec.png'),
      path: ['/product', 'logistix'],
      description: 'Create an enhanced delivery option for your customers.'
    },
    {
      icon: urlJoin(this.environment, 'products/portal/img/benefits/icon-3.png'),
      logo: urlJoin(this.environment, 'shared/img/product-logos/png/deliverytrax-oec.png'),
      path: ['/product', 'deliverytrax'],
      description: 'Our flagship logistics software, which allows suppliers to create, manage and analyze delivery routes.'
    },
    {
      icon: urlJoin(this.environment, 'products/portal/img/benefits/icon-6.png'),
      logo: urlJoin(this.environment, 'shared/img/product-logos/png/backtrax-oec.png'),
      path: ['/product', 'backtrax'],
      description: 'Track your part returns and get notifications when your account is credited.'
    },
    {
      icon: urlJoin(this.environment, 'products/portal/img/benefits/icon-5.png'),
      logo: urlJoin(this.environment, 'shared/img/product-logos/png/traxdrive-oec.png'),
      path: ['/product', 'traxdrive'],
      description: 'A companion app for DeliveryTrax™ that provides GPS tracking for drivers and signature capture.'
    }
  ];

  allVoices: any;
  voices: any;
  isTalking = false;
  showLogin = false;

  constructor() { }

  ngOnInit() {
  }

}
