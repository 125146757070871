<div class="qr-mechanical">
  <section class="header">
    <div class="hero-banner text-center">
      <div class="left-side">
        <img
          class="ops-tech-logo"
          [src]="environment + 'products/portal/img/qr-code-mechanical/opsbadge-tagline-white.png'"
          alt=""
        />
        <img
          class="hero-pic-mobile"
          [src]="environment + 'products/portal/img/qr-code-mechanical/hero-pic@2x.png'"
          alt="DeliveryTrax"
        />
        <h1>Ordering parts just got a lot easier!</h1>
        <div class="subtitle">
          Trax provides a fast and efficient means to order and track the
          delivery of parts to your shop.
        </div>
        <div class="connect">
          <button class="button btn-orange"><a href="https://register.opstrax.com/register.html" target="_blank">Register Now</a></button>
          <button class="button btn-blue">
            <i class="btn-icon-lock"></i
            ><span class="space"><a href="https://www.opstrax.com/opsportal/#/ops" target="_blank">Login Now</a></span>
          </button>
        </div>
      </div>
      <div class="right-side">
        <img
          class="hero-pic"
          [src]="environment + 'products/portal/img/qr-code-mechanical/hero-pic@2x.png'"
          alt="DeliveryTrax"
        />
      </div>
    </div>
  </section>
  <section class="feature pic-left padding-page background-gradient">
    <div class="tagline">
      <h3 class="padding-page">
        Take your team to the next level by using the industry’s #1 parts
        procurement tool.
      </h3>
      <div class="line"></div>
    </div>
    <div class="picture-section">
      <div>
        <img
          class="device-picture"
          [src]="environment + 'products/portal/img/qr-code-mechanical/epc-device-pic@2x.png'"
          alt="Deliverytrax feature"
        />
      </div>
      <div>
        <div class="item bullets mt-lg-5">
          <div class="icon-container">
            <img [src]="environment + 'products/portal/img/qr-code-mechanical/icon-returns@2x.png'" alt="" />
          </div>
          <div class="item-content">
            <p class="item-title">Electronic Returns & Credits</p>
            <p>
              BackTrax is a complete returns management system allowing you to
              track all your parts and core returns electronically. Receive
              instant credit memos from your suppliers that can be easily shared
              among all your departments.
            </p>
          </div>
        </div>
        <div class="item bullets mt-lg-5">
          <div class="icon-container">
            <img
              [src]="environment + 'products/portal/img/qr-code-mechanical/icon-communication@2x.png'"
              alt=""
            />
          </div>
          <div class="item-content">
            <p class="item-title">Instant Communication</p>
            <p>
              Send questions and messages regarding your parts orders to your
              suppliers, track any discrepancies on orders and get instant
              customer service.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <img
        class="device-picture-mobile"
        [src]="environment + 'products/portal/img/qr-code-mechanical/epc-device-pic@2x.png'"
        alt="Deliverytrax feature"
      />
      <p class="summary">
        Trax provides a fast and efficient means to order and track the
        delivery of parts to your shop. Our technology is designed to be a
        one-stop source to help you provide expert service and give you the
        tools needed to improve your business workflow.
      </p>
      <div class="mobile-center">
        <img
          class="mechanical-logo"
          [src]="environment + 'products/portal/img/qr-code-mechanical/trax-mechanical-logo@2x.png'"
          alt=""
        />
      </div>
      <h3 class="subtitle">
        We have expanded our industry-leading OPS Technology platform.
      </h3>
      <div class="item bullets">
        <div class="icon-container">
          <img [src]="environment + 'products/portal/img/qr-code-mechanical/icon-epc@2x.png'" alt="" />
        </div>
        <div class="item-content">
          <p class="item-title">Access to the Electronic Parts Catalog (EPC)</p>
          <ul>
            <li>Fast, efficient repairs</li>
            <li>Current parts data that is updated frequently</li>
            <li>VIN-specific searches that helps reduce error rates</li>
            <li>
              Exploded parts view that shows parts and part numbers associated
              with that assembly
            </li>
          </ul>
          <img [src]="environment + 'products/portal/img/qr-code-mechanical/epc-explode@2x.png'" alt="" />
        </div>
      </div>
      <div class="item bullets mt-lg-5 mt-lg-5-mobile">
        <div class="icon-container">
          <img
            [src]="environment + 'products/portal/img/qr-code-mechanical/icon-management@2x.png'"
            alt=""
          />
        </div>
        <div class="item-content">
          <p class="item-title">Order and Delivery Management</p>
          <p>
            Trax allows you to order <strong>all part types</strong> in one
            central location. Ends redundant phone calls and questions regarding
            ETA and delivery of parts.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="feature pic-right padding-page">
    <div class="content">
      <h3>Order Management and Status Tracking</h3>
      <p>
        Get access to up-front pricing, parts availability and delivery ETA from
        the nation’s largest network of parts suppliers.
      </p>
      <div class="items-container">
        <div class="column-1">
          <div class="item bullets mt-lg-5">
            <div class="icon-container">
              <img
                [src]="environment + 'products/portal/img/qr-code-mechanical/icon-tracker@2x.png'"
                alt=""
              />
            </div>
            <div class="item-content">
              <p class="item-title">
                Orders tracked from time of placement to order fulfillment
              </p>
            </div>
          </div>
          <div class="item bullets mt-lg-4">
            <div class="icon-container">
              <img
                [src]="environment + 'products/portal/img/qr-code-mechanical/icon-instant@2x.png'"
                alt=""
              />
            </div>
            <div class="item-content">
              <p class="item-title">Instant delivery notifications</p>
            </div>
          </div>
          <div class="item bullets mt-lg-5 mt-lg-5-mobile">
            <div class="icon-container">
              <img
                [src]="environment + 'products/portal/img/qr-code-mechanical/icon-invoice@2x.png'"
                alt=""
              />
            </div>
            <div class="item-content">
              <p class="item-title">
                Electronic invoice posting to management system
              </p>
            </div>
          </div>
          <div class="item bullets mt-lg-4">
            <div class="icon-container">
              <img
                [src]="environment + 'products/portal/img/qr-code-mechanical/icon-expedited@2x.png'"
                alt=""
              />
            </div>
            <div class="item-content">
              <p class="item-title">Expedited delivery in 2 hours or less</p>
            </div>
          </div>
        </div>
        <div class="column-2">
          <div class="item bullets mt-lg-5">
            <div class="icon-container">
              <img
                [src]="environment + 'products/portal/img/qr-code-mechanical/icon-network@2x.png'"
                alt=""
              />
            </div>
            <div class="item-content">
              <p class="item-title">Customization of supplier network</p>
            </div>
          </div>
          <div class="item bullets mt-lg-5">
            <div class="icon-container">
              <img
                [src]="environment + 'products/portal/img/qr-code-mechanical/icon-mobile@2x.png'"
                alt=""
              />
            </div>
            <div class="item-content">
              <p class="item-title-1">Mobile Ordering through TraxMobile</p>
              <p class="item-title">
                Get the power of Trax in the palm of your hands
              </p>
              <ul>
                <li>VIN scanning and validation</li>
                <li>Order tracking and status updates</li>
                <li>Access to the Electronic Parts Catalog (EPC)</li>
                <li>
                  Download the app by visiting the
                  <div class="store-btns">
                    <a class="btns" href="https://apps.apple.com/us/app/traxmobile-for-shops/id1316672379" target="_blank">
                      <img

                      [src]="environment + 'products/portal/img/qr-code-mechanical/store-mac-icon@2x.png'"
                      alt=""
                    />
                    </a>
                    <span>or</span
                    >
                    <a class="btns" href="https://play.google.com/store/apps/details?id=com.traxmobile" target="_blank">
                      <img [src]="environment + 'products/portal/img/qr-code-mechanical/store-play-icon@2x.png'"
                      alt=""
                    />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="picture-trax">
      <img
        [src]="environment + 'products/portal/img/qr-code-mechanical/trax-circle-picture@2x.png'"
        alt="Deliverytrax feature"
      />
    </div>
  </section>
  <section class="feature cta padding-page">
    <div class="texts">
      <h3>
        Enroll as an Trax shop and order all makes and models electronically
        through one single platform.
      </h3>
      <a href="https://www.opstrax.com/opsportal/#/ops" target="_blank">
        <img [src]="environment + 'products/portal/img/qr-code-mechanical/txt-cta@2x.png'" alt="" />
      </a>
    </div>
    <div class="blank"></div>
  </section>
</div>
<app-footer></app-footer>
