import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';



@Injectable()
export class ProductService {
  environment = environment.assets;
  endPoint = this.environment + 'products/portal/data/products.data.json';
  constructor(
    private http: HttpClient,
  ) { }
  private getProducts(): Promise<any> {
    // return this.http.get(this.endPoint).toPromise();
    return this.http.get('assets/data/products.data.json').toPromise();
  }
  get(productName: string): Promise<any> {
    const promise = new Promise(resolve => {
       this.getProducts().then(products => {
          const product = products.filter(p => p.name.toLowerCase() === productName.toLowerCase());
          if (product.length > 0) {
            resolve(product[0]);
          } else {
            resolve(null);
          }
      });
    });
    return promise;
  }
}

export interface IProduct {
  name: string;
  logo: string;
  headline: string;
  byline: string;
  descTitle: string;
  desc: string;
  descSpecification: string;
  partners: [IPartner];
  contentColumns: [IContentColumn];
}

export interface IPartner {
  headline: string;
  logos: [ILogos];
}

export interface ILogos {
  src: string;
  alt: string;
  href: string;
}

export interface IContentColumn {
  title: string;
  Items: [IItem];
}

export interface IItem {
  title: string;
  description: string;
  icon: string;
}
