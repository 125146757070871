import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class NewsService {
  constructor(
    private http: HttpClient,
  ) { }


  getNews(): Promise<any> {
    const url = environment.assets + 'products/portal/data/news.data.json';
    return this.http.get(url).toPromise();
  }

  get(newsName: string): Promise<any> {
    const promise = new Promise(resolve => {
      this.getNews().then(result => {
        const news = result.filter(r => r.name.toLowerCase() === newsName.toLowerCase());
        if (news.length > 0) {
          resolve(news[0]);
        } else {
          resolve(null);
        }
      });
    });
    return promise;
  }

}

export interface INews {
  name: string;
  isInternal: boolean;
  path: string;
  srcType: string;
  src: string;
  headline: string;
  byline: string;
  author: string;
  date: string;
  content: string;
  filter(arg0: (n: any) => boolean): INews;
}
