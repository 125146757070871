import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CommonApiService } from 'src/app/shared/services/common-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-idsrv4-legacy',
  templateUrl: './idsrv4-legacy.component.html',
  styleUrls: ['./idsrv4-legacy.component.scss']
})
export class Idsrv4LegacyComponent implements OnInit {

  username: string;
  password: string;
  error = 'Invalid username or password';
  showError = false;
  isLoading = false;
  currentYear: number = new Date().getFullYear();
  ronumber: string;
  environment = environment.assets;
  v2RedirectUrl = environment.v2RedirectUrl;

  constructor(
    public authService: AuthService,
    public commonsApiService: CommonApiService,
  ) { }

  ngOnInit(): void {
  }


  signin() {
    this.isLoading = true;
    this.showError = false;
    this.validateForm();
    this.authService.login(this.username, this.password).subscribe(accessToken => {
      const user = this.authService.getCurrentUser();
      this.isLoading = false;
      switch (user.userType) {
        case 1:
          this.commonsApiService.GetNavigationBarLinks({ id: 27, encrypted: true }).subscribe(result => {
            result.map(x => {
              if (x.app_id === 27) {
                if (this.ronumber === undefined) {
                  window.location.href = x.url + 'clientid-login?jwt=' + accessToken;
                } else {
                  window.location.href = x.url + 'login?atoken=' + accessToken + '&ronumber=' + this.ronumber;
                }
              }
            });
          });
          break;
        case 2:
          this.commonsApiService.GetNavigationBarLinks({ id: 25, encrypted: true }).subscribe(result => {
            result.map(x => {
              if (x.app_id === 25) {
                window.location.href = x.url + 'clientid-login?jwt=' + accessToken;
              }
            });
          });
          break;
        case 3:
          this.amISarvesh(accessToken);
          break;
        case 7:
          this.signinV2();
          break;
        case 4:
          this.authService.token = accessToken;
          this.commonsApiService.AddRedirectToken({ url: environment.dispatchRedirectUrl }).subscribe(data => {
            const grailsUrl = environment.dispatchRedirectUrl + 'crossAppAuth/jump?token=' + data.token;
            window.open(
              grailsUrl,
              '_blank'
            );
          });
          break;
      }
    },
      () => {
        window.location.href = environment.baseUrl;
        this.isLoading = false;
        this.showError = true;
      });
  }

  amISarvesh(accessToken: string) {
    if (this.username === 'svanka') {
      window.location.href = environment.v3adminRedirectUrl + accessToken;
    } else {
      this.signinV2();
    }
  }

  private validateForm() {
    let userValue = this.username;
    let passValue = this.password;
    if (this.username === undefined) {
      const user = document.getElementById('UserName') ?
        document.getElementById('UserName') : document.getElementById('username');
      userValue = user.innerHTML ? user.innerHTML : (user as any).value;
    }
    if (this.password === undefined) {
      const pass = document.getElementById('Password') ?
        document.getElementById('Password') : document.getElementById('password');
      passValue = pass.innerHTML ? pass.innerHTML : (pass as any).value;
    }
    this.username = userValue;
    this.password = passValue;
  }

  signinV2() {
    // tslint:disable-next-line: no-string-literal
    const formRef = document.forms[0];
    formRef.action = this.v2RedirectUrl;
    formRef.method = 'post';
    formRef.submit();
  }

}
