<app-navigation></app-navigation>

<div id="careers">

  <div class="careers">
    <div class="container">
      <h1>Careers</h1>
      <hr>
      <h4>Talented and out-of-the-box thinkers wanted!</h4>
      <p>Join a team that shaped todays industry and impacts tomorrows progress.</p>
    </div>
  </div>

  <div class="why-work-at-ops" id="why-ops">
    <div class="img-content right parallax"></div>
    <div class="container">
      <div class="text-content left">
        <div class="wrapper">
          <div class="positioner">
            <h3>Why work at OPS?</h3>
            <hr>
            <span>Our family of brilliant and dedicated people are guided by core values that promote innovation,
              integrity and ingenuity. Here are few reasons you should bring your career to OPS…</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="solutions" id="solutions">
    <div class="img-content left"></div>
    <div class="container">
      <div class="text-content right">
        <div class="wrapper">
          <div class="positioner">
            <h3>Head of the Class Solutions</h3>
            <hr>
            <span>Our industry-leading solutions create unmatched experiences for our clients. The technologies, culture
              and benefits create amazing opportunities for you.</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="workplace" id="workplace">
    <div class="img-content right parallax"></div>
    <div class="container">
      <div class="text-content left">
        <div class="wrapper">
          <div class="positioner">
            <h3>AN INSPIRING WORKPLACE</h3>
            <hr>
            <span>Join a tight-knit community of smart, passionate experts who thrive at OPS. Take pride in a culture
              that promotes family, dedication and commitment to each other. Work in an environment built to push your
              creative limits and care for your well-being.</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="career-growth" id="career-growth">
    <div class="img-content left"></div>
    <div class="container">
      <div class="text-content right">
        <div class="wrapper">
          <div class="positioner">
            <h3>CAREER GROWTH</h3>
            <hr>
            <span>We foster the future growth and development of our team members, augmenting their talents through
              training and guidance to ensure they continue to grow professionally.</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="opportunities" id="opportunities">
    <div class="container">
      <h3>current opportunities</h3>
      <hr>
      <table>
        <thead>
          <tr>
            <th>Job Title</th>
            <th>Description</th>
            <th>Requirements</th>
            <th>Select</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let career of careers">
            <td><a [routerLink]="['/career' , career.path]">{{career.position}}</a></td>
            <td>{{career.descriptionShort}}</td>
            <td>{{career.requirementsShort}}</td>
            <td><button (click)="onApply(career.position)">e-mail</button></td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>

  <div class="tiles">
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6">
            <div class="text-wrapper blue">
              <div class="text-positioner">
                <h3>20+ Years in business</h3>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="img-wrapper first"></div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6">
            <div class="text-wrapper blue">
              <div class="text-positioner">
                <h3>CUTTING EDGE OF TECHNOLOGY</h3>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="img-wrapper second"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-top: -2px;">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6">
            <div class="img-wrapper third"></div>
          </div>
          <div class="col-md-6">
            <div class="text-wrapper grey">
              <div class="text-positioner">
                <h3>FAMILY ENVIRONMENT</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6">
            <div class="img-wrapper fourth"></div>
          </div>
          <div class="col-md-6">
            <div class="text-wrapper grey">
              <div class="text-positioner">
                <h3>LEADER IN INDUSTRY</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</div>

<app-footer></app-footer>
