<div class="traxdrive">
  <section class="header">
    <div class="top-bar padding-page">
      <div class="logo">
        <img
          [src]="
            environment + 'products/portal/img/traxmobile-ad/traxdrive-logo.svg'
          "
          alt="Deliverytrax logo"
        />
      </div>
    </div>
    <div class="hero-banner text-center">
      <div>
        <h1>Stay connected while on the move!</h1>
        <div class="pill">
          Taking it to the next level while improving your overall delivery
          experience one step at a time!
        </div>
        <div class="connect">
          <form
            ngNoForm
            action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
            method="POST"
          >
            <input type="hidden" name="oid" value="00D37000000IOhS" />
            <input type="hidden" name="retURL" value="{{ confirmationUrl }}" />
            <input
              type="hidden"
              id="lead_source"
              name="lead_source"
              value="DeliveryTrax landing Form"
            />
            <input
              type="text"
              id="email"
              maxlength="80"
              name="email"
              class="search-box"
              placeholder="Provide your email here to connect with an expert…"
            />
            <input class="search-button" type="submit" value="Submit" />
          </form>
        </div>
      </div>
      <img
        class="mobile-pic"
        [src]="
          environment + 'products/portal/img/traxmobile-ad/hero-banner@2x.png'
        "
        alt="DeliveryTrax"
      />
    </div>
    <div class="tagline"></div>
  </section>
  <section class="feature pic-left padding-page">
    <img
      [src]="
        environment + 'products/portal/img/traxmobile-ad/first-section@2x.png'
      "
      alt="Deliverytrax feature"
    />
    <div class="content">
      <h2>Be prepared for the <span class="orange">“New” </span>release.</h2>
      <ul>
        <li>
          If your phone is setup to automatically update your applications,<span
            class="orange"
          >
            you will not need to do anything</span
          >
          as your phone will update to the new version.
        </li>
        <li>
          If your phone is setup to manually update,
          <span class="orange"
            >you will need to go to the Apple AppStore or to Google Play</span
          >
          to update the new TraxDrive application.
        </li>
        <li>
          Routes are now visible in TraxDrive before being shipped and
          <span class="orange">can be shipped directly from TraxDrive</span> if
          you have permissions to change routes.
        </li>
      </ul>
    </div>
  </section>
  <section class="feature pic-center padding-page">
    <div class="content">
      <h2>
        Take a sneak peek of the <span class="orange">“New” workflow</span>
      </h2>
      <div class="line-separator"></div>
    </div>
    <img
      [src]="
        environment +
        'products/portal/img/traxmobile-ad/workflow@3x.png'
      "
      alt="Deliverytrax feature"
    />
  </section>

  <section class="features-glance padding-page">
    <div class="title text-center">
      <h2>
        Check out the <span class="orange">“New” features</span> that will help
        improve your business workflow
      </h2>
      <div class="line-separator"></div>
    </div>
    <div class="features-container m-t-5">
      <div class="feature-item one">
        <div class="title-fea">
          <img
            [src]="environment + 'products/portal/img/traxmobile-ad/feat1.svg'"
            alt="Deliverytrax logo"
          />
          <h3>Choose between Online or Off-Line capabilities</h3>
        </div>

        <p>
          With the new offline feature, you can use any device with or without a
          data plan.
        </p>
        <ul>
          <li>
            When TraxDrive is used in the offline mode it will download the
            delivery information from your Wi-Fi network and uploads the proof
            of deliveries when you get back to your facility.
          </li>
          <li>
            Making deliveries in areas with a weak or no cellular service just
            got a lot easier. With TraxDrive you no longer need to wait for the
            information to upload to DeliveryTrax, even without a connection the
            information will be recorded and available once you obtain a network
            connection.
          </li>
        </ul>
      </div>
      <div class="feature-item two">
        <div class="title-fea">
          <img
            [src]="environment + 'products/portal/img/traxmobile-ad/feat2.svg'"
            alt="Deliverytrax logo"
          />
          <h3>Increased visibility and efficiency for drivers</h3>
        </div>
        <p>
          Drivers can now preview their routes and check their parts as they are being loaded.  Drivers also can ship their routes independently to accept the delivery.
        </p>
        <div class="title-fea">
          <img
            [src]="environment + 'products/portal/img/traxmobile-ad/feat4.svg'"
            alt="Deliverytrax logo"
          />
          <h3>Increased Photo options  </h3>
        </div>
        <p>
          A driver is no longer limited on the number of photos they take.
        </p>
        <div class="title-fea">
          <img
            [src]="environment + 'products/portal/img/traxmobile-ad/feat6.svg'"
            alt="Deliverytrax logo"
          />
          <h3>Additional Options for Tracking Deliveries </h3>
        </div>
        <p>
          With In-Pocket Delivery you can use the drivers geo-fenced area to notify your customers once parts have been delivered.
        </p>
      </div>
      <div class="feature-item three">
        <div class="title-fea">
          <img
            [src]="environment + 'products/portal/img/traxmobile-ad/feat3.svg'"
            alt="Deliverytrax logo"
          />
          <h3>A new streamlined appearance for an enhanced user experience   </h3>
        </div>
        <p>
          The new enriched design of TraxDrive helps to reduce training times for new drivers.  Everything is easily accessible and intuitive to meet every driver’s needs.
        </p>
        <div class="title-fea">
          <img
            [src]="environment + 'products/portal/img/traxmobile-ad/feat5.svg'"
            alt="Deliverytrax logo"
          />
          <h3>Return Email Notifications </h3>
        </div>
        <p>
          Eliminates the need for Return Slips.  Set an email address for automated notifications on Returns picked up with TraxDrive.
        </p>
      </div>
    </div>
  </section>

  <section class="feature pic-right padding-page m-b-t">
    <div class="content">
      <h2>
        <span class="orange">Empower your staff</span> with online training and
        Expert Q&A
      </h2>
      <p>
        Get ease of mind knowing you can train new staff or provide refreshers
        to your team at a moment’s notice with our live webinar sessions.
      </p>
    </div>
    <div class="webinars">
      <div class="box">
        <h5>Live Webinar Training</h5>
        <div class="item">
          <h4>
            <a
              href="https://attendee.gotowebinar.com/rt/5560935492203963138"
              target="_blank"
              >Detailed TraxDrive – Driver Mobile App Training<span> (30 Mins)</span></a
            >
          </h4>
          <p>
            Detailed Training on TraxDrive. Includes instructions on
            installation and usage from both the dispatcher and driver’s
            perspective.
          </p>
        </div>
      </div>
      <div class="bg-feature"></div>
    </div>
  </section>

  <section class="quote">
    <div class="quote-content padding-page">
      <h1>
        Keep an eye out for these new features
        <span class="orange">coming soon!</span>
      </h1>
    </div>
  </section>
</div>

<div class="confirmation-modal" *ngIf="status == 200">
  <div class="title-header">
    <h6>Thank you for Contacting Us!</h6>
  </div>
  <div class="content">
    <div class="check">
      <svg
        width="100"
        height="100"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:svg="http://www.w3.org/2000/svg"
      >
        <g>
          <g id="svg_1">
            <path
              id="svg_2"
              class="envelope"
              d="m5.6914,8l44.309,34.465l44.312,-34.465l-88.621,0zm-2.6953,2.9766l0,65.023c0,1.1055 0.89844,2.0039 2.0039,2l39.668,0c-0.42969,-1.7656 -0.66406,-3.6055 -0.66406,-5.5c0,-12.906 10.59,-23.504 23.496,-23.504s23.496,10.598 23.496,23.504c0,1.8945 -0.23438,3.7344 -0.66406,5.5l4.6641,0c1.1055,0 2,-0.89453 2,-2l0,-65.016l-45.766,35.594c-0.72266,0.5625 -1.7344,0.5625 -2.4609,0l-45.77298,-35.601z"
            />
            <path
              id="svg_3"
              class="check"
              d="m67.5,53c-10.746,0 -19.504,8.7578 -19.504,19.5c0,10.746 8.7578,19.496 19.504,19.496s19.504,-8.75 19.504,-19.496c0,-10.742 -8.7578,-19.5 -19.504,-19.5zm8.457,12.984c0.82422,-0.02344 1.582,0.46094 1.9023,1.2188s0.14453,1.6367 -0.44531,2.2109l-10.004,9.9961c-0.77734,0.78125 -2.0469,0.78125 -2.8281,0l-4.9961,-4.9961c-0.39453,-0.37109 -0.625,-0.88672 -0.63281,-1.4258c-0.00781,-0.54297 0.20312,-1.0664 0.58594,-1.4492c0.38281,-0.38281 0.90625,-0.59375 1.4492,-0.58594c0.53906,0.01172 1.0547,0.23828 1.4258,0.63281l3.5781,3.582l8.5938,-8.5859c0.36328,-0.37109 0.85547,-0.58203 1.3711,-0.59766l0.00008,-0.00001z"
            />
          </g>
        </g>
      </svg>
    </div>
    <h6>
      Thank you for submitting your email. An OPS customer representative will
      respond to you shortly.
    </h6>
  </div>
  <button class="btn btn-primary" (click)="onDismiss()">Close</button>
</div>
<div class="background-modal" *ngIf="status == 200"></div>
<app-footer></app-footer>
