import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, AfterViewInit {

  currentYear: number = new Date().getFullYear();

  private element: any;

  showLogin = false;
  showAnnoucement = false;

  isOnPrivacyPage = false;


  constructor(private viewportScroller: ViewportScroller,
              private router: Router
    ) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe(() => {
      if (this.router.url.indexOf('privacy-policy') > 0) {
        this.isOnPrivacyPage = true;
      } else {
        this.isOnPrivacyPage = false;
      }
    });
  }

  ccpaPolicy() {
    if (this.isOnPrivacyPage) {
      this.viewportScroller.scrollToAnchor('ccpa');
      const highlightDiv = document.getElementById('ccpa');
      highlightDiv.classList.add('highlight');
      setTimeout(() => {
        highlightDiv.classList.remove('highlight');
      }, 11000);
    } else {
      this.router.navigate(['/privacy-policy', 'ccpa']);
    }
  }

}
