<div id="login">
    <div class="back-web pull-right">
      <a [routerLink]="['/ops']">
        <button class="back-btn"><i class="fa fa-arrow-left"></i>Back to OPS Site</button>
      </a>
    </div>
    <div class="login-content">
      <div class="ops-logo">
        <img draggable="false" [src]="environment + 'shared/img/product-logos/png/opsbadge.png'" alt="OPS">
        <div>Technologies</div>
      </div>
      <form>
        <button (click)="login()">
          <span >LOGIN</span>
        </button>
      </form>
  
      <div class="copyright">Overall Parts Solutions &copy; {{currentYear}}. All Rights Reserved.</div>
    </div>
  
  </div>
