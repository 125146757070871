<app-navigation></app-navigation>

<div id="contactus">

  <div class="container">
    <div class="row">
      <div class="col-md-3">

        <div class="support-sidebar">

          <h3>Need Tech Support?</h3>
          <hr>
          <!-- <a href="http://support.opstrax.com/" target="_blank" class="btn btn-primary btn-lg"><i class="fa fa-fw fa-comments"></i>SUPPORT</a> -->
          <a [routerLink]="['/','support']" target="_blank" class="btn btn-primary btn-lg"><i
              class="fa fa-fw fa-comments"></i>SUPPORT</a>
          <p class="c-font-thin">If you need technical support use this link to chat live with someone from our
            support
            staff.</p>
        </div>

      </div>

      <div class="col-md-9">

        <div class="c-content-title-1">
          <h3>Ready to revolutionize your business?</h3>
          <hr>
        </div>

        <div class="c-content-title-1">
          <h4>Contact us today to get your subscription started.</h4>
          <p>OPS is proud to be the industry standard in electronic parts procurement (EPP). If you're interested in our
            solutions, or want to get your subscription started, we're happy to help. Simply fill out the form below or
            call us at 877-873-8729.</p>
        </div>

        <form ngNoForm action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
          name="contactUsForm" method="POST">

          <input type=hidden name='captcha_settings'
            value='{"keyname":"opstrax_master","fallback":"true","orgId":"00D37000000IOhS","ts":""}'>
          <input type=hidden name="oid" value="00D37000000IOhS">
          <input type=hidden name="retURL" value="{{confirmationUrl}}">
          <input type=hidden id="lead_source" name="lead_source" value="Contact Us - OPSTRAX.com">


          <h4>1. How can we contact you?</h4>

          <div class="row" style="margin-top: 24px;">

            <div class="col-md-6">
              <h6>First Name <small class="required">(Required)</small></h6>
              <input id="first_name" maxlength="40" name="first_name" size="20" type="text" class="form-control"
                required><br>
            </div>

            <div class="col-md-6">
              <h6>Last Name <small class="required">(Required)</small></h6>
              <input id="last_name" maxlength="40" name="last_name" size="20" type="text" class="form-control"
                required><br>
            </div>

          </div>

          <div class="row">

            <div class="col-md-6">
              <h6>Phone <small class="required">(Required)</small></h6>
              <input id="phone" mask='(000)-000-0000' name="phone" type="phone" class="form-control" required><br>
            </div>

            <div class="col-md-6">
              <h6>Email <small class="required">(Required)</small></h6>
              <input id="email" maxlength="40" name="email" size="20" type="email" class="form-control" required><br>
            </div>

          </div>

          <div class="row">

            <div class="col-md-6">
              <h6>Job Title</h6>
              <input id="title" name="title" size="20" type="text" class="form-control"><br>
            </div>

          </div>

          <h4>2. Tell us about your company...</h4>

          <div class="row" style="margin-top: 24px;">

            <div class="col-md-6">
              <h6>Company Name <small class="required">(Required)</small></h6>
              <input id="company" name="company" type="text" class="form-control" required><br>
            </div>

            <div class="col-md-6">
              <h6>Website</h6>
              <input id="url" name="url" type="text" class="form-control"><br>
            </div>

          </div>

          <div class="row">

            <div class="col-md-6">
              <h6>Street</h6>
              <input id="street" name="street" type="text" class="form-control"><br>
            </div>

            <div class="col-md-6">
              <h6>City</h6>
              <input id="city" name="city" type="text" class="form-control"><br>
            </div>

          </div>

          <div class="row">

            <div class="col-md-6">
              <h6>State/Province</h6>

              <select id="state" name="state" class="form-control">
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select><br>

              <!-- <input id="state" name="state" type="text" class="form-control"><br> -->
            </div>

            <div class="col-md-6">
              <h6>Zipcode</h6>
              <input id="zip" name="zip" type="text" class="form-control"><br>
            </div>

          </div>

          <div class="row">

            <div class="col-md-6">
              <h6>Industry</h6>
              <select name="industry" id="industry" name="industry" class="form-control">
                <option value="">--None--</option>
                <option value="Collision Repairer">Collision Repairer</option>
                <option value="OEM Parts Vendor">OEM Parts Vendor</option>
                <option value="Specialty Parts &amp; Accessory Vendor">Specialty Parts &amp; Accessory Vendor</option>
                <option value="Biotechnology">Biotechnology</option>
                <option value="Chemicals">Chemicals</option>
                <option value="Communications">Communications</option>
                <option value="Construction">Construction</option>
                <option value="Consulting">Consulting</option>
                <option value="Education">Education</option>
                <option value="Electronics">Electronics</option>
                <option value="Energy">Energy</option>
                <option value="Engineering">Engineering</option>
                <option value="Entertainment">Entertainment</option>
                <option value="Environmental">Environmental</option>
                <option value="Finance">Finance</option>
                <option value="Food &amp; Beverage">Food &amp; Beverage</option>
                <option value="Government">Government</option>
                <option value="Healthcare">Healthcare</option>
                <option value="Hospitality">Hospitality</option>
                <option value="Insurance">Insurance</option>
                <option value="Machinery">Machinery</option>
                <option value="Manufacturing">Manufacturing</option>
                <option value="Media">Media</option>
                <option value="Not For Profit">Not For Profit</option>
                <option value="Other">Other</option>
                <option value="Recreation">Recreation</option>
                <option value="Retail">Retail</option>
                <option value="Shipping">Shipping</option>
                <option value="Technology">Technology</option>
                <option value="Telecommunications">Telecommunications</option>
                <option value="Transportation">Transportation</option>
                <option value="Utilities">Utilities</option>
              </select>
              <br>
            </div>

          </div>

          <h4>3. What OPS products or services interest you?</h4>

          <h6>Want to see a demo? Have a question about OPSTRAX and how to sign up? <small
              class="required">(Required)</small></h6>
          <textarea id="00N1S000007IWxL" name="00N1S000007IWxL" rows="5" wrap="soft" class="form-control"
            required></textarea>

          <div style="margin-top: 25px;" class="g-recaptcha" data-sitekey="6LdOac8UAAAAAIph3pqWUlJRy1bAAsKI2zH6uWMP"
            required></div><br>

          <button class="btn btn-lg btn-primary" type="submit" value="Submit">submit</button>


        </form>

        <div class="c-content-title-1">
          <h4>Need Technical Assistance?</h4>
          <p> If you need technical assistance, help adding and removing users, resetting passwords or changing your
            login
            information, please visit our technical help and support site
            <a href="http://support.opstrax.com/" class="">HERE</a>.
            You can also call 877-873-8729 option 2 to reach our support department.
          </p>
        </div>

      </div>
    </div>

  </div>

</div>

<div class="confirmation-modal" *ngIf="status == 200">
  <div class="title-header">
    <h6>Thank you for Contacting Us!</h6>
  </div>
  <div class="content">
    <div class="check">
      <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
        <g>
          <g id="svg_1">
            <path id="svg_2" class="envelope"
              d="m5.6914,8l44.309,34.465l44.312,-34.465l-88.621,0zm-2.6953,2.9766l0,65.023c0,1.1055 0.89844,2.0039 2.0039,2l39.668,0c-0.42969,-1.7656 -0.66406,-3.6055 -0.66406,-5.5c0,-12.906 10.59,-23.504 23.496,-23.504s23.496,10.598 23.496,23.504c0,1.8945 -0.23438,3.7344 -0.66406,5.5l4.6641,0c1.1055,0 2,-0.89453 2,-2l0,-65.016l-45.766,35.594c-0.72266,0.5625 -1.7344,0.5625 -2.4609,0l-45.77298,-35.601z" />
            <path id="svg_3" class="check"
              d="m67.5,53c-10.746,0 -19.504,8.7578 -19.504,19.5c0,10.746 8.7578,19.496 19.504,19.496s19.504,-8.75 19.504,-19.496c0,-10.742 -8.7578,-19.5 -19.504,-19.5zm8.457,12.984c0.82422,-0.02344 1.582,0.46094 1.9023,1.2188s0.14453,1.6367 -0.44531,2.2109l-10.004,9.9961c-0.77734,0.78125 -2.0469,0.78125 -2.8281,0l-4.9961,-4.9961c-0.39453,-0.37109 -0.625,-0.88672 -0.63281,-1.4258c-0.00781,-0.54297 0.20312,-1.0664 0.58594,-1.4492c0.38281,-0.38281 0.90625,-0.59375 1.4492,-0.58594c0.53906,0.01172 1.0547,0.23828 1.4258,0.63281l3.5781,3.582l8.5938,-8.5859c0.36328,-0.37109 0.85547,-0.58203 1.3711,-0.59766l0.00008,-0.00001z" />
          </g>
        </g>
      </svg>
    </div>
    <h6>Thank you for submitting your email. An OPS customer representative will respond to you shortly.
    </h6>
  </div>
  <button class="btn btn-primary" (click)="onDismiss()">Close</button>
</div>
<div class="background-modal" *ngIf="status == 200"></div>
<app-footer></app-footer>
