import { ICareers, CareersService } from './../../shared/services/careers.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit, AfterViewInit {

  careers: ICareers;
  private fragment: string;

  constructor(
    private careersService: CareersService,
    private route: ActivatedRoute,
    private viewportScroller: ViewportScroller) { }

  ngOnInit() {
    this.careersService.getCareers().then( careers => {
      this.careers = careers;
    });

    this.route.queryParams.subscribe(params => {
      const anchor = this.route.snapshot.paramMap.get('anchor');
      if (anchor) {
        this.viewportScroller.scrollToAnchor(anchor);
      }
    });

  }

  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) { }
  }

  onApply(position: string) {
    window.location.href = 'mailto: careers@opstrax.com?subject=' + position + ' Job Inquiry';
  }

}
