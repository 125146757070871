export const environment = {
  production: true,
  baseUrl: 'https://www.opstrax.com/opsportal/#/',
  identityApiBaseUrl: 'https://identity.opstrax.com/api/ida4/',
  clientId: '099153c2625149bc8ecb3e85e03f0022',
  shoptraxRedirectUrl: 'https://awsv3.opstrax.com/shop/#/clientid-login?jwt=',
  shoptraxBaseUrl: 'https://awsv3.opstrax.com/shop/#/',
  dealertraxRedirectUrl: 'https://awsv3.opstrax.com/dealer/#/clientid-login?jwt=',
  dispatchRedirectUrl: 'https://dispatch.opstrax.com/traxd/',
  v2RedirectUrl: 'https://www.opstrax.com/status/login2.cfm',
  environment: 'prod',
  commonAPI: 'https://awsv3.opstrax.com/api/OPSCommon/',
  opsEmailsUrl: 'https://awsv3.opstrax.com/OpsEmails',
  v3adminRedirectUrl: 'https://awsv3.opstrax.com/v3admin/#/clientid-login?jwt=',
  assets: 'https://assets.opstrax.com/',
  stsSettings: {
    // authority: 'https://localhost:44386/',
    authority: 'https://api.dev1.opstrax.com/api/opsidp',
    client_id: 'opsportal',
    redirect_uri: `${window.location.origin}/#/signin-callback`,
    scope: 'openid profile opscommonapi.full',
    response_type: 'code',
    post_logout_redirect_uri: `${window.location.origin}/#/signout`,
    automaticSilentRenew: true,
    silent_redirect_uri: `${window.location.origin}/assets/silent-callback.html`,
  }
};
