import { environment } from 'src/environments/environment';
import { ICareers, CareersService } from './../../../shared/services/careers.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-career-details',
  templateUrl: './career-details.component.html',
  styleUrls: ['./career-details.component.scss']
})
export class CareerDetailsComponent implements OnInit {

  careerName = '';
  career: ICareers;
  environment = environment.assets;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private careerService: CareersService) { }

  ngOnInit() {
    window.scroll(0, 0);

    this.careerName = this.route.snapshot.paramMap.get('careerName');
    this.careerService.get(this.careerName).then(result => {
      this.career = result;
      if (!this.career) {
         this.router.navigate(['/page-not-found']);
      }
    });
  }

  onBack() {
    this.router.navigate(['/careers', 'opportunities']);
  }

  onApply() {
    window.location.href = 'mailto: careers@opstrax.com?subject=' + this.career.position + ' Job Inquiry';
  }

}
