import { MetaService } from './shared/services/meta.service';
import { EhiService } from './shared/services/ehi.service';
import { CommonApiService } from './shared/services/common-api.service';
import { EmailService } from './shared/services/email.service';
import { EventEmitService } from './shared/services/event-emit.service';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from './shared/services/auth.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, SafePipe } from './app.component';
import { FooterComponent } from './layout/footer/footer.component';
import { NavigationComponent } from './layout/navigation/navigation.component';
import { LoginComponent } from './login/app-login/login.component';
import { LandingComponent } from './features/landing/landing.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdsComponent } from './shared/components/ads/ads.component';
import { ProductComponent } from './features/product/product.component';
import { PageNotFoundComponent } from './layout/pagenotfound/pagenotfound.component';
import { ProductService } from './shared/services/products.service';
import { NewsFeedComponent } from './features/news-feed/news-feed.component';
import { NewsService } from './shared/services/news.service';
import { NewsContentComponent } from './features/news-feed/news/news-content.component';
import { ContentComponent } from './features/product/content/content.component';
import { BenefitsComponent } from './features/benefits/benefits.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AboutComponent } from './features/about/about.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ContactusComponent } from './features/contactus/contactus.component';
import { SupportComponent } from './features/support/support.component';
import { CareersComponent } from './features/careers/careers.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CareersService } from './shared/services/careers.service';
import { CareerDetailsComponent } from './features/careers/career-details/career-details.component';
import { RegisterComponent } from './features/register/register.component';
import { SubscribeComponent } from './features/subscribe/subscribe.component';

import { EhiLoginComponent } from './login/ehi-login/ehi-login.component';
import { HeaderInterceptor } from './shared/interceptors/header-interceptor';
import { AdminPanelLoginComponent } from './login/admin-panel-login/admin-panel-login.component';
import { JsLoader } from './shared/helpers/js-loader';
import { CompactLoginComponent } from './shared/components/compact-login/compact-login.component';
import { PrivacyPolicyComponent } from './features/policy/privacy-policy/privacy-policy.component';
import { CcpaPolicyComponent } from './features/policy/ccpa-policy/ccpa-policy.component';
import { CookieConsentComponent } from './shared/components/cookie-consent/cookie-consent.component';
import { SellInformationComponent } from './shared/components/sell-information/sell-information.component';
import { CookieService } from 'ngx-cookie-service';
import { FastsupportComponent } from './features/support/fastsupport/fastsupport.component';
import { DtraxAdComponent } from './features/dtrax-ad/dtrax-ad.component';
import { TraxmobileAdComponent } from './features/traxmobile-ad/traxmobile-ad.component';
import { QrLeadAdComponent } from './features/qr-lead-ad/qr-lead-ad.component';
import { EncryptionService } from './shared/services/encryption.service';
import { LoginOpsComponent } from './login/login-ops/login-ops.component';
import { CarouselComponent } from './shared/components/carousel/carousel.component';
import { NewsComponent } from './shared/components/news/news.component';
import { AnnouncementComponent } from './shared/components/announcement/announcement.component';
import { CompactLoginV2Component } from './shared/components/compact-login-v2/compact-login-v2.component';
import { RedirectComponent } from './login/redirect/redirect.component';
import { Idsrv4LoginComponent } from './login/idsrv4-login/idsrv4-login.component';
import { Auth2Service } from './shared/services/auth2.service';
import { SigninCallbackComponent } from './login/signin-callback/signin-callback.component';
import { SignoutCallbackComponent } from './login/signout-callback/signout-callback.component';
import { Idsrv4LegacyComponent } from './login/idsrv4-legacy/idsrv4-legacy.component';

@NgModule({
  declarations: [
    AppComponent,
    SafePipe,
    FooterComponent,
    NavigationComponent,
    LoginComponent,
    LandingComponent,
    CarouselComponent,
    NewsComponent,
    CompactLoginComponent,
    AdsComponent,
    ProductComponent,
    PageNotFoundComponent,
    NewsFeedComponent,
    NewsContentComponent,
    ContentComponent,
    BenefitsComponent,
    AboutComponent,
    ContactusComponent,
    SupportComponent,
    CareersComponent,
    CareerDetailsComponent,
    RegisterComponent,
    SubscribeComponent,
    EhiLoginComponent,
    AdminPanelLoginComponent,
    AnnouncementComponent,
    PrivacyPolicyComponent,
    CcpaPolicyComponent,
    CookieConsentComponent,
    SellInformationComponent,
    FastsupportComponent,
    DtraxAdComponent,
    TraxmobileAdComponent,
    LoginOpsComponent,
    CompactLoginV2Component,
    RedirectComponent,
    QrLeadAdComponent,
    Idsrv4LoginComponent,
    SigninCallbackComponent,
    SignoutCallbackComponent,
    Idsrv4LegacyComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxPaginationModule,
    CarouselModule,
    BrowserAnimationsModule,
  ],
  providers: [
    AuthService,
    Auth2Service,
    ProductService,
    NewsService,
    EventEmitService,
    CareersService,
    EmailService,
    CommonApiService,
    EhiService,
    MetaService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    JsLoader,
    EncryptionService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
