<app-navigation></app-navigation>
<div class="product-hero jumbotron-fluid">
  <div class="container">
    <div class="product-content mt-5">
      <div class="content-title plr-15">
        <h3 class="font-uppercase font-bold">OPS Cookies Policy</h3>
        <div class="line-left orange-bg"></div>
      </div>
      <p><strong>Privacy is top priority for OPS</strong></p>
      <p>&nbsp;</p>
      <p>
        <strong>WHAT DOES THIS POLICY APPLY TO? </strong><strong>&nbsp;</strong>
      </p>

    </div>
  </div>

  <app-footer></app-footer>
</div>
