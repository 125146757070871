import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { INews, NewsService } from 'src/app/shared/services/news.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.scss']
})
export class NewsFeedComponent implements OnInit {
  environment = environment.assets;
  news: INews;
  currentPage = 1;

  constructor(
    private newsService: NewsService) { }

  ngOnInit() {
    this.newsService.getNews().then( news => {
      news = news.filter(n => !n.isPrivate);
      this.news = _.sortBy(news, ['date']).reverse();
    });
  }
  onPageChange(page: number) {
    this.currentPage = page;
  }

}
