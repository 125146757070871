import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class CommonApiService  {

  apiUrl = environment.commonAPI;

  constructor(private httpClient: HttpClient) {
  }

  GetNavigationBarLinks(body: any): Observable<IApplicationLink[]> {
    return this.httpClient.post<IApplicationLink[]>(this.apiUrl + 'application/v2/navigation', body, this.getOptions());
  }

  AddRedirectToken(body: any): Observable<IAddRedirectToken> {
    return this.httpClient.post<IAddRedirectToken>(this.apiUrl + 'redirect', body, this.getOptions());
  }


  getOptions() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return { headers };
  }


}

export interface ISearchRequest {
  type: string;
  criteria: string;
  features: string;
  from?: Date;
  to?: Date;
}

export interface IApplicationLink {
  app_id: number;
  name: string;
  url: string;
  description: string;
  logo: string;
  enabled: boolean;
  lang_code: string;
  user_application_access: number;
  app_feature_code: string;
  isActive?: boolean;

}


export interface IAddRedirectToken {
  url: string;
  token: string;
}
