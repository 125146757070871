<div id="login">

    <div class="login-content" *ngIf="showForm">
      <div class="ops-logo">
        <img draggable="false" [src]="environment + 'shared/img/product-logos/png/opsbadge.png'" alt="OPS">
        <div>Technologies</div>
      </div>
      <form (ngSubmit)="getToken()" >
        <span>
          <label [ngClass]="(showError) ? 'error-label':''" for="username">Username</label>
          <input [ngClass]="(showError) ? 'error-input':''" type="text" name="username" [(ngModel)]="username" placeholder="Enter Username" />
        </span>

        <span>
          <label [ngClass]="(showError) ? 'error-label':''" for="password">password</label>
          <input [ngClass]="(showError) ? 'error-input':''" type="password" name="password" [(ngModel)]="password" style="-webkit-text-security: circle;" placeholder="Enter Password" />
        </span>

        <p [ngClass]="(showError) ? 'error':''" *ngIf="showError">{{error}}</p>

        <!-- <label class="input">
          <input name="rememberMe" type="checkbox" class="input__field">
          <span class="input__text checkbox"> Remember Me</span>
        </label> -->

        <a class="forgot-password" href="https://admin.opstrax.com/admind/forgotLogin" target="_blank">Forgot Password?</a>

        <button type="submit" [class]="(isLoading)? '':'btn'" [disabled]="isLoading">
          <span *ngIf="!isLoading">LOGIN</span>
          <span *ngIf="isLoading"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>
        </button>

      </form>

      <div class="copyright">Overall Parts Solutions &copy; {{currentYear}}. All Rights Reserved.</div>

    </div>

  </div>
