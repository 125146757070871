import { Component, OnInit, AfterViewInit } from '@angular/core';
import { EventEmitService, GenericEvent, GENERIC_EVENT_TYPE } from 'src/app/shared/services/event-emit.service';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit {

  private element: any;

  currentYear: number = new Date().getFullYear();

  isOnPrivacyPage = false;

  constructor(
              private viewportScroller: ViewportScroller,
              private router: Router,
              private eventEmitService: EventEmitService) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe(() => {
      if (this.router.url.indexOf('privacy-policy') > 0) {
        this.isOnPrivacyPage = true;
      } else {
        this.isOnPrivacyPage = false;
      }
    });
  }

  ccpaPolicy() {
    if (this.isOnPrivacyPage) {
      this.viewportScroller.scrollToAnchor('ccpa');
      const highlightDiv = document.getElementById('ccpa');
      highlightDiv.classList.add('highlight');
      setTimeout(() => {
        highlightDiv.classList.remove('highlight');
      }, 11000);
    } else {
      this.router.navigate(['/privacy-policy', 'ccpa']);
    }
  }

  redirect(path: string[]) {
    this.eventEmitService.emitGenericEvent(new GenericEvent(GENERIC_EVENT_TYPE.UPDATE_PRODUCT, path[1]));
  }

}
