import { ViewportScroller } from '@angular/common';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-ops',
  templateUrl: './login-ops.component.html',
  styleUrls: ['./login-ops.component.scss']
})
export class LoginOpsComponent implements OnInit, AfterViewInit {
  currentYear: number = new Date().getFullYear();

  private element: any;

  showLogin = false;
  showAnnoucement = false;

  isOnPrivacyPage = false;

  constructor(private viewportScroller: ViewportScroller,
              private router: Router) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe(() => {
      if (this.router.url.indexOf('privacy-policy') > 0) {
        this.isOnPrivacyPage = true;
      } else {
        this.isOnPrivacyPage = false;
      }
    });
  }

  ccpaPolicy() {
    if (this.isOnPrivacyPage) {
      this.viewportScroller.scrollToAnchor('ccpa');
      const highlightDiv = document.getElementById('ccpa');
      highlightDiv.classList.add('highlight');
      setTimeout(() => {
        highlightDiv.classList.remove('highlight');
      }, 11000);
    } else {
      this.router.navigate(['/privacy-policy', 'ccpa']);
    }
  }

}
