import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit, AfterViewInit {

  private fragment: string;

  constructor(private route: ActivatedRoute,
              private viewportScroller: ViewportScroller) { }

  ngOnInit() {
    window.scroll(0, 0);
    this.route.queryParams.subscribe(params => {
      const anchor = this.route.snapshot.paramMap.get('anchor');
      if (anchor) {
        this.viewportScroller.scrollToAnchor(anchor);
        const highlightDiv = document.getElementById(anchor);
        highlightDiv.classList.add('highlight');
        setTimeout(() => {
          highlightDiv.classList.remove('highlight');
        }, 11000);
      }
    });
  }

  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) { }
  }

}
