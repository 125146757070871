import { CookieService } from 'ngx-cookie-service';
import { MetaService } from './shared/services/meta.service';
import { Component, Pipe, PipeTransform, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Title, Meta } from '@angular/platform-browser';
import { CookieConsentService } from './shared/services/cookie-consent.service';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Angular Universal Example';

  constructor(
    private router: Router,
    private titleService: Title,
    private meta: MetaService,
    private cookieServ: CookieService,
    private cookieConsentService: CookieConsentService,
    ) {
      this.meta.updateTitle();
     }

    ngOnInit() {
      if (this.cookieServ.check('opsCookie')) {
      } else {
        setTimeout(() => {
          document.getElementById('btnOpenConsent').click();
         }, 300);
      }
    }

    openModal(id: string) {
      this.cookieConsentService.open(id);
    }

    closeModal(id: string) {
      this.cookieConsentService.close(id);
    }

}
