<app-navigation></app-navigation>

<div id="support">
  <div class="container">
    <!-- BEGIN: PAGE CONTENT -->
    <div class="c-content-title-1">
      <div class="row">
        <div class="col-md-3">
          <div class="support-sidebar">

            <h3>Need Tech Support?</h3>
            <hr>
            <p class="c-font-thin">Our support team is best-in-class!
              If you need technical help please fill out our form to submit a ticket.
              You can also call <b>877-873-8729</b> to get in touch with our support department.
              <br>
              <br>
              For email support, send to <span style="font-size: 16px;">support@oeconnection.com</span>
              <br>
              <br>
              Our support team is available Monday - Friday 8:00am - 8:00pm EST.
            </p>
          </div>
        </div>
        <div class="col-md-9">
          <div class="col-md-12" class="remote-connect">
            <div class="c-content-title-1">
              <h3>Remote Support</h3>
              <hr>
              <!-- <h6>Ask away! Let our dedicated team get your questions answered.</h6> -->
            </div>
            <div class="remote-content">
              <span>For a remote support session, click the button to the right and enter the Support Key provided to
                you from the OPS Support Technician.</span>

              <button (click)="navigateToScreenConnect()" class="btn btn-primary">Remote Connect</button>
            </div>

          </div>
          <div class="col-md-12">
            <form [formGroup]="supportEmailForm">

              <p style="color:red" *ngIf="showError">*{{error}}</p>
              <div class="row">
                <div class="col-md-12">
                  <div class="c-content-title-1">
                    <h3>Request Support</h3>
                    <hr>
                    <h6>Ask away! Let our dedicated team get your questions answered.</h6>
                  </div>
                </div>
              </div>


              <div class="row" style="margin-top: 24px;">
                <div class="col-md-6">
                  <h6>Name <small class="required">(Required)</small></h6>
                  <input id="name" maxlength="40" formControlName="name" size="20" type="text" class="form-control"
                    [ngClass]="{'is-invalid': supportEmailForm.controls.name.invalid && (supportEmailForm.controls.name.dirty || supportEmailForm.controls.name.touched)}"
                    required><br>
                </div>

                <div class="col-md-6">
                  <h6>Phone <small class="required">(Required)</small></h6>
                  <input id="phone" formControlName="phone" size="20" type="tel" class="form-control"
                    [ngClass]="{'is-invalid': supportEmailForm.controls.phone.invalid && (supportEmailForm.controls.phone.dirty || supportEmailForm.controls.phone.touched)}"
                    pattern="[0-9]{3}[0-9]{3}[0-9]{4}" required><br>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <h6>Email <small class="required">(Required)</small></h6>
                  <input id="email" maxlength="40" formControlName="email" size="20" type="email" class="form-control"
                    [ngClass]="{'is-invalid': supportEmailForm.controls.email.invalid && (supportEmailForm.controls.email.dirty || supportEmailForm.controls.email.touched)}"
                    required><br>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <h6>Subject <small class="required">(Required)</small></h6>
                  <input id="subject" maxlength="40" formControlName="subject" size="20" type="text"
                    [ngClass]="{'is-invalid': supportEmailForm.controls.subject.invalid && (supportEmailForm.controls.subject.dirty || supportEmailForm.controls.subject.touched)}"
                    class="form-control" required><br>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <h6>Message <small class="required">(Required)</small></h6>
                  <textarea formControlName="description" id="description" rows="5" wrap="soft" class="form-control"
                    [ngClass]="{'is-invalid': supportEmailForm.controls.description.invalid && (supportEmailForm.controls.description.dirty || supportEmailForm.controls.description.touched)}"
                    required></textarea><br>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <h6>I am a: <small class="required">(Required)</small></h6>
                  <select id="wholesaler-type" formControlName="wholeSalerType"
                    [ngClass]="{'is-invalid': supportEmailForm.controls.wholeSalerType.invalid && (supportEmailForm.controls.wholeSalerType.dirty || supportEmailForm.controls.wholeSalerType.touched)}"
                    class="form-control" required>
                    <option value="">Please select</option>
                    <option value="Dealer">Dealer</option>
                    <option value="Shop">Shop</option>
                    <option value="Others">Others</option>
                  </select><br>
                </div>
              </div>

              <div class="row"
                *ngIf="supportEmailForm.controls.wholeSalerType.value && supportEmailForm.controls.wholeSalerType.value === 'Dealer'">
                <div class="col-md-12">
                  <h6>OEM Dealer Code <small class="required">(Required)</small></h6>
                  <input id="oemDealerCode" maxlength="15" formControlName="oemDealerCode" type="text"
                    [ngClass]="{'is-invalid': supportEmailForm.controls.oemDealerCode.invalid && (supportEmailForm.controls.oemDealerCode.dirty || supportEmailForm.controls.oemDealerCode.touched)}"
                    class="form-control" required><br>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <button [ngClass]="{'btn btn-lg btn-primary':!isLoading}" type="submit" (click)="sendSupportEmail()"
                    [disabled]="isLoading || supportEmailForm.invalid">
                    <span *ngIf="!isLoading">Submit</span>
                    <span *ngIf="isLoading"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i></span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>


<div class="confirmation-modal" *ngIf="status == 200">
  <div class="title-header">
    <h6>Thank you for Contacting Us!</h6>
  </div>
  <div class="content">
    <div class="check">
      <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
        <g>
          <g id="svg_1">
            <path id="svg_2" class="envelope"
              d="m5.6914,8l44.309,34.465l44.312,-34.465l-88.621,0zm-2.6953,2.9766l0,65.023c0,1.1055 0.89844,2.0039 2.0039,2l39.668,0c-0.42969,-1.7656 -0.66406,-3.6055 -0.66406,-5.5c0,-12.906 10.59,-23.504 23.496,-23.504s23.496,10.598 23.496,23.504c0,1.8945 -0.23438,3.7344 -0.66406,5.5l4.6641,0c1.1055,0 2,-0.89453 2,-2l0,-65.016l-45.766,35.594c-0.72266,0.5625 -1.7344,0.5625 -2.4609,0l-45.77298,-35.601z" />
            <path id="svg_3" class="check"
              d="m67.5,53c-10.746,0 -19.504,8.7578 -19.504,19.5c0,10.746 8.7578,19.496 19.504,19.496s19.504,-8.75 19.504,-19.496c0,-10.742 -8.7578,-19.5 -19.504,-19.5zm8.457,12.984c0.82422,-0.02344 1.582,0.46094 1.9023,1.2188s0.14453,1.6367 -0.44531,2.2109l-10.004,9.9961c-0.77734,0.78125 -2.0469,0.78125 -2.8281,0l-4.9961,-4.9961c-0.39453,-0.37109 -0.625,-0.88672 -0.63281,-1.4258c-0.00781,-0.54297 0.20312,-1.0664 0.58594,-1.4492c0.38281,-0.38281 0.90625,-0.59375 1.4492,-0.58594c0.53906,0.01172 1.0547,0.23828 1.4258,0.63281l3.5781,3.582l8.5938,-8.5859c0.36328,-0.37109 0.85547,-0.58203 1.3711,-0.59766l0.00008,-0.00001z" />
          </g>
        </g>
      </svg>
    </div>
    <h6>Thank you for submitting your question to us online. An OPS customer representative will respond to you shortly.
    </h6>
  </div>
  <button class="btn btn-primary" (click)="onDismiss()">Close</button>
</div>
<div class="background-modal" *ngIf="status == 200"></div>

<app-footer></app-footer>

<!-- generate email to support@opstrax.com -->
