import { Auth2Service } from './../../shared/services/auth2.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signout-callback',
  templateUrl: './signout-callback.component.html',
  styleUrls: ['./signout-callback.component.scss']
})
export class SignoutCallbackComponent implements OnInit {

  constructor(private authService: Auth2Service, private router: Router) { }

  ngOnInit(): void {
    this.authService.completeLogout().then(_ => {
      this.router.navigate(['/'], { replaceUrl: true });
    });
  }

}
