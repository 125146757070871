import { Component, OnInit } from '@angular/core';
import { JsLoader } from 'src/app/shared/helpers/js-loader';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  status = 0;
  confirmationUrl = environment.baseUrl + 'contactus?status=200';

  environment = environment.assets;

  constructor(
    private jsLoader: JsLoader,
    private router: Router,
    private Activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.jsLoader.loadRecaptchaScript();
    this.jsLoader.loadSalesForceScript();
    this.Activatedroute.queryParamMap
    .subscribe(params => {
      this.status = +params.get('status') || 0;
    });

    if (this.status === 200) {
      const body = document.getElementById('body');
      body.classList.add('no-scroll');
    }
  }

  onDismiss() {
    this.router.navigate(['/contactus']);
    const body = document.getElementById('body');
    if (body.classList.contains('no-scroll')) {
      body.classList.remove('no-scroll');
    }
  }

}

