<app-navigation></app-navigation>
<div class="product-hero jumbotron-fluid">
  <div class="container">
    <div class="product-content mt-5">
      <div class="content-title plr-15">
        <h3 class="font-uppercase font-bold">
          California Consumer Privacy Act and OPS
        </h3>
        <div class="line-left orange-bg"></div>
      </div>
      <small><em>Effective Date: January 1, 2020</em></small>
      <br />
      <small><em>Last Updated: June 30, 2020</em> </small>
      <p>
        The California Consumer Privacy Act (CCPA) is a regulation governed by
        the state of California which supports California residents. This
        California Consumer Privacy Act of California (2018)  applies only
        to websites and applications operated by Overall Parts Solutions (OPS).
      </p>
      <p>
        <strong>This California Consumers</strong> <strong>Privacy</strong> <strong>Policy</strong> describes
        your rights and control over your personal information, including the
        right to know, the right to delete, and the right to opt out of the sale
        of personal information that OPS collects, as well as additional
        protections for minors. These are in addition to the rights set forth in
        the OPS Privacy Policy Statement.
      </p>
      <p><strong>PERSONAL INFORMATION OPS COLLECTS FROM YOU</strong></p>
      <p>
        <strong>OPS collects information about you in different ways. When you do
          business with OPS, we may collect various types of personal
          information about you, your purchase and use of our products and
          services, and your use of our websites and applications and their
          content.</strong><strong> </strong>
      </p>
      <p>
        Personal data&mdash;meaning information that could help us identify
        you&mdash;could include:
      </p>
      <p><strong>Identifying contact information, such as: </strong></p>
      <ul>
        <li>
          Your business name, job title, industry, and contact information such
          as email address and work and mobile phone numbers.
        </li>
        <li>Online identifier, user ID.</li>
        <li>
          In some cases, billing information when you register for the Products.
        </li>
      </ul>
      <p>
        We also keep track of your preferences when you select settings within
        the Products.
      </p>
      <p><strong>Demographic information, such as: </strong></p>
      <ul>
        <li>Zip code, age, gender, preferences, interests, and hobbies.</li>
      </ul>
      <p>
        <strong>CATEGORIES OF SOURCES OPS COLLECT PERSONAL DATA FROM</strong>
      </p>
      <p>
        <strong>We collect data about you from First- and Third-Party Analytics, </strong>which are integrated in our
        website and applications. They are placed
        to provide data to help OPS better understand and serve our customers,
        and improve our service levels, customer experience, and products.
      </p>
      <p>We may collect:</p>
      <ul>
        <li>
          <strong>Demographic information</strong> such as gender, age,
          geolocation data, and zip code.
        </li>
        <li>
          <strong>Certain information on interests and preferences.</strong>
        </li>
        <li>
          <strong>Information </strong>about your computer, user IP address,
          phone type, tablet, or other device you use to access the Products.
        </li>
        <li>
          <strong>Personal information</strong> which may include, but is not
          limited to, name, business address and title, email, online
          identifier, and credit card demographics (zip code, age, interests,
          time logged in on your platform).
        </li>
      </ul>
      <p><strong> </strong></p>
      <p><strong>HOW OPS COLLECTS PERSONAL INFORMATION</strong></p>
      <p>
        We collect data and information about you from a variety of sources and
        in a variety of ways including:
      </p>
      <ul>
        <li>
          Directly from you when you register for an account, create or modify
          your profile, set preferences, and sign up for or make purchases
          through the Products we offer.
        </li>
        <li>
          When you attend an event that OPS is attending and you visit our
          booth.
        </li>
        <li>
          When you provide payment information when you register for certain
          paid Products.
        </li>
        <li>
          When you provide additional information through our support channels,
          such as identifying information (such as name, company, email, phone
          number, or title).
        </li>
        <li>
          Through the use our Products, including information on how you
          interact with the Products, what devices you use to access the
          Products, demographic information, and information on interests and
          preferences.
        </li>
        <li>
          From other users, from third-party services, from our related
          companies, and from our business and channel partners, such as when
          another user supplies your email address to forward you an article, or
          from a third-party advertising research partner.
        </li>
        <li>Through first- and third-party cookies.</li>
      </ul>
      <p>
        <strong>When you visit other websites, attend OPS events, or complete OPS
          surveys</strong>: OPS may collect other content that you submit to websites, which may
        include social media or social networking pages operated by
        us&shy;&mdash;such as information related to your social media activity
        (e.g., posts, photos, followers, social media handle). Additionally, if
        you attend an event or respond to a survey OPS is conducting, you may
        provide content to us when you participate in any interactive features,
        surveys, contests, promotions, sweepstakes, activities, or events. This
        may include information such as: Your name, company you work for, job
        title, industry, email address, zip code, and work and mobile phone
        numbers.
      </p>
      <p><strong>SHARING PERSONAL INFORMATION </strong></p>
      <p>
        OPS may disclose your personal information to a third party for a
        business purpose. When we disclose personal information for a business
        purpose, we enter a contract that describes the purpose and requires the
        recipient to both keep that personal information confidential and not
        use it for any purpose except performing the contract. The CCPA
        prohibits third parties who purchase the personal information we hold
        from reselling it unless you have received explicit notice and an
        opportunity to opt out of further sales.
      </p>
      <p>
        We share your personal information with the following categories of
        third parties:
      </p>
      <ul>
        <li>Service providers</li>
        <li>Marketing partners</li>
        <li>Affiliated companies</li>
        <li>Business transfers</li>
      </ul>
      <p>
        <strong>BUSINESS PURPOSES OPS COLLECTS YOUR PERSONAL INFORMATION FOR</strong>
      </p>
      <ol>
        <li>
          To create, maintain, customize, and secure your account with us.
        </li>
        <li>
          To fill orders and to perform
          necessary <strong>business </strong>functions such as processing your requests, purchases, transactions,
          and payments and preventing
          transactional fraud.
        </li>
        <li>
          To help us better understand and meet our customers&rsquo; needs and
          experience.
        </li>
        <li>
          To improve our website functionality and help maintain the safety,
          security, and integrity of our website.
        </li>
        <li>
          To provide, support, personalize, and develop our website, products,
          and services.
        </li>
        <li>
          To help us refine our marketing strategies through research and
          analytics and through tracking consumer web and application activities
          via internal and third-party tagging and cookies.
        </li>
      </ol>
      <p>Examples of Personal Information and Categories:</p>
      <table>
        <tbody>
          <tr>
            <td style="width: 286px;">
              <p>
                <strong>CATEGORIES OF PERSONAL INFORMATION OPS COLLECTS</strong>
              </p>
            </td>
            <td style="width: 290px;">
              <p>
                <strong>CATEGORIES OF THIRD PARTIES WE SHARE THAT INFORMATION
                  WITH</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td style="width: 286px;">
              <p>
                Identifiers such as: A real name, alias, postal address, unique
                personal identifier, IP address, email address, and account name
              </p>
            </td>
            <td style="width: 290px; vertical-align: top;">
              <ul>
                <li>Business Partners</li>
                <li>Service Providers</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td style="width: 286px; vertical-align: top;">
              <p>
                Geolocation data such as: region, state, zip code, and devices
                (computer and mobile)
              </p>
            </td>
            <td style="width: 290px;">
              <ul>
                <li>Business Partners</li>
                <li>Service Providers</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        <br /><strong>CATEGORIES OF FIRST-PARTY AND THIRD-PARTY COOKIES OPS USES</strong>
      </p>
      <p>
        When you visit our website or use our
        applications, we and unrelated parties place a cookie on your computer
        to track and store activity on our website and applications. Tracking
        technologies used are beacons, tags, and scripts to collect and track
        information and to improve and analyze our products and services.
      </p>
      <p>
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if you do not accept cookies, you may
        not be able to use our website, products, or services.
      </p>
      <p><strong>Use of Cookies</strong></p>
      <p>
        This cookie policy applies to use of this site, and not use of cookies
        in connection with OPS websites and applications not directly accessible
        from another OPS website or application.
      </p>
      <p><strong>First Party </strong></p>
      <p>
        <strong>General Purpose:</strong> OPS places a cookie on your machine to
        collect analytics data, remember language settings and login
        information, and perform other useful functions that provide a good user
        experience. They are necessary/essential cookies, without which the OPS
        site and its applications would not work or function properly, or
        provide you certain features and functionalities. They help to
        authenticate users and prevent fraudulent use of user accounts.
      </p>
      <p>
        When you visit our website or use our applications, this allows OPS to
        better understand the needs of our consumers with regard to our content
        marketing and advertising efforts. These cookies analyze and track site
        visits and activities on the site driven from OPS advertising. OPS does
        share information with third parties that help us operate, provide,
        improve, integrate, customize, support, and market our Products.
      </p>
      <p>
        Some of these may be disabled, which may affect your functionality and
        experience on our sites and applications.
      </p>
      <p><strong>First-Party Necessary/Essential Cookies </strong></p>
      <p>
        <strong>Purpose:</strong> These cookies are essential for providing you
        with services available through the OPS website and to enable you to use
        some of its features.
      </p>
      <p>
        Our necessary website cookies collect the following information that
        identifies, relates to you, described by the following cookies
        below:
      </p>
      <table>
        <tbody>
          <tr>
            <td width="120">
              <p>Cookie</p>
            </td>
            <td width="102">
              <p>Name</p>
            </td>
            <td width="114">
              <p>Retention Period</p>
            </td>
            <td width="288">
              <p>Purpose</p>
            </td>
          </tr>
          <tr>
            <td width="120">
              <p>Strictly Necessary</p>
            </td>
            <td width="102">
              <p>Solar Winds</p>
            </td>
            <td width="114">
            </td>
            <td width="288">
              <p>
                Performance, Functionality, Targeting. More information:
                <a
                  href="https://www.solarwinds.com/cookies#:~:text=Cookies%20are%20small%20text%20files,receive%20cookies%20from%20several%20sources.">https://www.solarwinds.com/cookies#:~:text=Cookies%20are%20small%20text%20files,receive%20cookies%20from%20several%20sources.</a>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>  </p>
      <p><strong>Third-Party Cookies</strong></p>
      <p>
        <strong>Purpose:</strong> Third-party cookies are created by domains
        that are not websites (or domains) that you are visiting. These are
        usually used for online advertising purposes and are placed on a website
        through adding scripts or tags. A third-party cookie is accessible on
        any website and application that loads the third-party servers. We do not
        control the settings of these third-party cookies. Check the third-party
        websites for more information about their use of cookies and how to
        manage them.
      </p>
      <p>
        We use both session and persistent cookies for the purposes set out
        below:
      </p>
      <table width="630">
        <tbody>
          <tr>
            <td width="125">
              <p>Name</p>
            </td>
            <td width="125">
              <p>Source</p>
            </td>
            <td width="125">
              <p>Duration</p>
            </td>
            <td width="256">
              <p>Purpose</p>
            </td>
          </tr>
          <tr>
            <td width="125">
              <p>_ga</p>
            </td>
            <td width="125">
              <p>Google Analytics</p>
            </td>
            <td width="125">
              <p>2 years</p>
            </td>
            <td width="256">
              <p>
                These cookies are used to collect information about how visitors
                use the OPS website. That information is used in reports and in
                analysis to assist us with improving our websites and
                applications.
              </p>
            </td>
          </tr>
          <tr>
            <td width="125">
              <p>_gid</p>
            </td>
            <td width="125">
              <p>Google Analytics</p>
            </td>
            <td width="125">
              <p>24 hours</p>
            </td>
            <td width="256">
              <p>
                Used to distinguish users. Helps with information on how users
                use our site.
              </p>
            </td>
          </tr>
          <tr>
            <td width="125">
              <p>_gat</p>
            </td>
            <td width="125">
              <p>Google Analytics</p>
            </td>
            <td width="125">
              <p>1 min.</p>
            </td>
            <td width="256">
              <p>Helps OPS to identify user.</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>  </p>
      <p>
        <strong>OPS DOES NOT SELL PERSONAL INFORMATION ABOUT YOU TO THIRD
          PARTIES</strong>
      </p>
      <p>
        Although OPS does not sell any of our consumers&rsquo; data, certain
        guidelines are instituted to prevent the mishandling of confidential
        information while fulfilling the request(s) to retrieve, opt out of,
        and/or delete such data. Users can set the ads running on their sites to
        not send personal information, such as buying history and browsing
        history, to Google. This will prevent the advertisers from targeting you
        with personalized ads. Learn how to <a
          href="https://support.google.com/accounts/answer/2662856#everywhere">get your ad preferences on all your
          devices</a>.
      </p>
      <p>
        Some of your data collected from this site is used to help create
        better, more personalized products and services and to send ads and
        offers tailored to your interests. Occasionally this is done with help
        from third parties. We understand if you&rsquo;d rather us not share
        your information and respect your right to disable this sharing of your
        data with third parties for this browser, device, and property. If you
        turn this off, you will not receive personalized ads, but you will still
        receive ads. Note that any choice you make here will only affect this
        website on this browser and device.<br /><br />
        You can contact us by e-mail at
        <a href="mailto:support@opstrax.com">support@opstrax.com</a>, by
        telephone at [877-873-8729], or write to us by post at:
      </p>
      <p style="text-align: center;margin-bottom: 0;">Overall Parts Solutions</p>
      <p style="text-align: center;margin-bottom: 0;">1450 Post and Paddock St</p>
      <p style="text-align: center;margin-bottom: 0;">Grand Prairie, TX 75050</p>
      <p><strong>THE RIGHT TO DISCLOSE </strong></p>
      <p>
        You have the right to access general information about the personal
        information OPS collects, sells, or shares for business purposes.
        Information that is available to you under the Right to Access includes
        the following types of information with respect to the preceding
        12-month period:
      </p>
      <ol>
        <li>
          The
          <strong>categories and specifics of personal information </strong>OPS
          has <strong>collected</strong> about you.
        </li>
        <li>
          The categories of <strong>sources</strong> from which OPS has
          obtained your personal information.
        </li>
        <li>
          Our <strong>purpose</strong> for collecting your personal information.
        </li>
        <li>
          The <strong>categories of third parties</strong> with whom OPS
          has <strong>shared</strong> your personal information and the
          specific pieces of personal information collected.
        </li>
      </ol>
      <p><strong>OPS STORES DATA UNTIL </strong></p>
      <p>
        How long we keep information we collect about you depends on the type of
        information we have collected about you. We will also retain your
        information as necessary to comply with our legal obligations, resolve
        disputes, and enforce our agreements. After such a time, we will either
        delete or anonymize your information or, if this is not possible (for
        example, because the information has been stored in backup archives),
        then we will securely store your information and isolate it from any
        further use until deletion is possible.
      </p>
      <p>
        <strong>Your Account Information:</strong> We retain your
        account information for as long as your account is active and a
        reasonable period thereafter in case you decide to re-activate the
        Products. We also retain some of your information as necessary to
        comply with our legal obligations, to resolve disputes, to enforce our
        agreements, to support business operations, and to continue to develop
        and improve our Products. We also keep personal data as otherwise
        required by law.
      </p>
      <p>
        <strong>Managed accounts:</strong> If the Products are made
        available to you through an organization (e.g., your employer), we
        retain your information as long as required by your employer.
      </p>
      <p>
        <strong>Marketing information:</strong> If you have elected to
        receive marketing emails from us, we retain information about your
        marketing preferences for a reasonable period of time from the date you
        last expressed interest in our Products, such as when you last opened an
        email from us or ceased using your OPS account. We retain information
        derived from cookies and other tracking technologies for a reasonable
        period of time from the date such information was created.
      </p>
      <p><strong>HOW TO EXERCISE THE RIGHT TO ACCESS</strong></p>
      <p>
        You have the following rights to access, with respect to, your personal
        data and information collected by OPS. To exercise your rights:
      </p>
      <ul>
        <li>
          You may submit a request for access to the data we process about you.
        </li>
        <li>You may object to the processing of your data.</li>
        <li>You may access and update your information.</li>
        <li>You may deactivate your account.</li>
        <li>You may opt out of communications.</li>
        <li>
          You may request an overview, in a commonly used format, of the data we
          process about you.
        </li>
        <li>
          You may request correction or deletion of the data if it is incorrect
          or not or no longer relevant, or to ask to restrict the processing of
          the data.
        </li>
        <li>
          You may request that we stop using your data where you believe we
          don&rsquo;t have the appropriate rights to do so.
        </li>
        <li>Please provide OPS sufficient information such as:</li>
        <ul style="margin-top: 10px;">
          <li>
            properly verifying you are the person about whom we collected personal
            information, or that you are an authorized representative of that
            person;
          </li>
          <li>
            describing your request with sufficient detail that allows OPS to
            properly understand, evaluate, and respond to it.
          </li>
        </ul>
      </ul>
      <p><strong>YOUR RIGHT TO DELETE</strong></p>
      <p>
        California residents have the right under the CCPA to request that OPS
        delete any of their personal information that we have collected and
        retained, subject to certain exceptions. Once we receive and confirm a
        verifiable consumer request (see Exercise the Right to Access), OPS will
        delete or anonymize (and direct our service providers to delete or
        anonymize) relevant personal information from our records, unless an
        exception applies.
      </p>
      <p>
        OPS may deny California residents&rsquo; deletion requests if retaining
        the information is necessary for OPS to service our clients, such as to:
      </p>
      <ul>
        <li>
          complete the transaction for which we collected the personal
          information, provide a service that you requested, take actions
          reasonably anticipated within the context of our ongoing business
          relationship with the requesting individual, or otherwise perform our
          contract with a requesting individual;
        </li>
        <li>
          detect security incidents; protect against malicious, deceptive,
          fraudulent, or illegal activity; or prosecute those responsible for
          such activities;
        </li>
        <li>
          debug products to identify and repair errors that impair existing
          intended functionality;
        </li>
        <li>
          exercise free speech, ensure the right of another consumer to exercise
          their free speech rights, or exercise another right provided for by
          law;
        </li>
        <li>
          comply with the California Electronic Communications Privacy Act (Cal.
          Penal Code &sect; 1546 et. seq.);
        </li>
        <li>
          enable solely internal uses that are reasonably aligned with consumer
          expectations;
        </li>
        <li>comply with a legal obligation;</li>
        <li>
          or to make other internal and lawful uses of that information that are
          compatible with the context in which you provided it.
        </li>
      </ul>
      <p>
        <strong>HOW TO EXERCISE ACCESS, DATA PORTABILITY, AND DELETION RIGHTS AND OPT OUT</strong>
      </p>
      <p>
        You have the right to opt out of the sale of your personal information
        and to exercise the access, data portability, and deletion rights under
        the CCPA described above. To do so, please submit a verifiable consumer
        request to us by any of the following: E-mail us at
        <a href="mailto:support@opstrax.com">support@opstrax.com</a>, call us at
        telephone number [877-873-8729], or write to us by post at:
      </p>
      <p style="text-align: center;margin-bottom: 0;">Overall Parts Solutions</p>
      <p style="text-align: center;margin-bottom: 0;">1450 Post and Paddock St</p>
      <p style="text-align: center;margin-bottom: 0;">Grand Prairie, TX 75050</p>
      <p><strong>OTHER OPT OUT OPTIONS</strong></p>
      <p>
        <strong>Opt out of communications:</strong> You may opt out of
        receiving promotional communications from us by using the unsubscribe
        link within each promotional product email communication, by updating
        your email preferences by contacting OPS Support, or by contacting us as
        provided below to have your contact information removed from our
        promotional email list or registration database. Even after you opt out
        from receiving promotional messages from us, you will continue to
        receive transactional messages (such as a receipt for a purchase) from
        us regarding our Products. Please note that regardless of your
        email settings, we may send you notifications pertaining to the
        performance of our Products, such as the revision of our Terms of
        Service, Privacy Policy, or other formal communications relating to
        Products or Services you have purchased or used.
      </p>
      <p>
        <strong>Mobile Consents/Opt-Outs</strong>: We may send you messages,
        including marketing messages, via your wireless email address or by
        short message service (SMS), wireless access protocol (WAP) services, or
        mobile phone service. By providing us with your wireless email address
        or by signing up for any SMS, WAP, or mobile phone service, you consent
        to receiving services and messages at the address or number you provided
        for such purposes and understand that your wireless carrier&rsquo;s
        rates apply to these services/messages. You may opt-out of SMS, WAP, or
        mobile services by following the instructions provided in the
        message.
      </p>
      <p><strong>RESPONSE AND TIMING</strong></p>
      <p>
        OPS will respond to a verifiable request within forty-five (45) days.
        However, in certain circumstances, we may require additional time to
        process your request, as permitted by the CCPA or other applicable law.
        We will advise you within forty-five (45) days after receiving your
        request if such an extension is necessary and why it is needed. Any
        disclosures we provide will only cover the 12-month period preceding our
        receipt of your verifiable consumer request.
      </p>
      <p>
        If we cannot fulfill your request, our response to you will also explain
        the reason why we cannot fulfill your request.
      </p>
      <p><strong>RIGHT NOT TO BE DISCRIMINATED AGAINST</strong></p>
      <p>
        OPS does not discriminate, deny, or subject anyone person to OPS
        services, good or pricing. We do not discriminate against you for
        exercising your CCPA rights, unless permitted by CCPA laws. Therefore,
        OPS will not:
      </p>
      <ul>
        <li>Deny you accesses to our products or services.</li>
        <li>Charge you different prices or rates for products or services.</li>
        <li>
          Provide you a different level or quality of products or services.
        </li>
        <li>
          Suggest that you may receive a different price or rate for products or
          services.
        </li>
      </ul>
      <p><strong>CHILDREN'S PRIVACY</strong></p>
      <p>
        Our service does not address anyone under the age of 16. We do not
        knowingly collect personally identifiable information from anyone under
        the age of 16. If you are a parent or guardian and you are aware that
        your child has provided us with personal data, please contact us. If we
        become aware that we have collected personal data from anyone under the
        age of 13 without verification of parental consent, we take steps to
        remove that information from our servers.
      </p>
      <p>
        If we need to rely on consent as a legal basis for processing your
        information and your country requires consent from a parent, we may
        require your parents&rsquo; consent before we collect and use that
        information.
      </p>
      <p><strong>Contact Us</strong></p>
      <p>
        If you have any questions about this Privacy Policy, you can contact us:
        E-mail us at
        <a href="mailto:support@opstrax.com">support@opstrax.com</a>, call us by
        telephone at <a href="tel:+1-877-873-8729">877-873-8729</a>, or write to us by post at:
      </p>
      <p style="text-align: center;margin-bottom: 0;">Overall Parts Solutions</p>
      <p style="text-align: center;margin-bottom: 0;">1450 Post and Paddock St</p>
      <p style="text-align: center;margin-bottom: 80px;">Grand Prairie, TX 75050</p>
    </div>
  </div>

  <app-footer></app-footer>
</div>
