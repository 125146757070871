<div class="product-hero jumbotron-fluid"
  [ngStyle]="{background: 'url(' + environment + 'products/portal/img/products/' + (product?.name | lowercase) + '.jpg)'}">
  <div class="container">
    <div class="logo-container">
      <img [src]="environment + product?.logo" [alt]="product?.name">
    </div>
    <div class="tagline">{{product?.headline}}</div>
    <div class="byline">{{product?.byline}}</div>
    <a class="btn btn-primary" [routerLink]="'/contactus'">Schedule Demo</a>
    <a class="btn btn-secundary" href="https://register.opstrax.com/register.html" target="_blank">Register</a>
  </div>
</div>
<div class="container">
  <div class="product-content">
    <hr>
    <h2>{{product?.descTitle}}</h2>
    <p>{{product?.desc}}</p>
    <small *ngIf="product?.descSpecification">{{product?.descSpecification}}</small>
  </div>

  <div *ngIf="product?.partners">
    <div *ngFor="let partner of product?.partners" class="partners">
      <h2>{{partner?.headline}}</h2>
      <div class="logos-container">
        <a target="_blank" *ngFor="let logo of partner?.logos" [href]="logo?.href">
          <img [src]="environment + logo?.src" [alt]="logo?.alt">
        </a>
      </div>
    </div>
  </div>

  <div class="row product-columns">
    <div class="col-md-6" *ngFor="let column of product?.contentColumns">
      <h3 *ngIf="column?.title" [innerHTML]="column?.title"></h3>
      <div *ngFor="let item of column.items">
        <h4> <i class="fa fw {{item?.icon}}"></i> {{item?.title}}</h4>
        <p [innerHTML]="item?.description"></p>
      </div>
    </div>
  </div>

</div>
