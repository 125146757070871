import { environment } from './../../../environments/environment';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { EncryptionService } from '../services/encryption.service';
import { Auth2Service } from '../services/auth2.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  excludedUrls = [];


  constructor(private auth: AuthService, private encryptionService: EncryptionService,
              private auth2: Auth2Service) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // HERE REQUEST INTERCEPTOR
    // Get the auth token from the service.
    const endPoint = environment.identityApiBaseUrl + 'oauth2/token';
    const excludedUrls = [
      environment.assets + 'products/portal/data/news.data.json',
      endPoint
    ];
    let authReq: any;
    if (excludedUrls.some(x => x === req.url)) {
      authReq = req.clone();
    } else {
      const token = this.auth.getAccessToken() ? this.auth.getAccessToken() : this.auth2.getAccessToken();
      if (req.body && req.body.encrypted === true) {
        const jsonBody = JSON.stringify(req.body);
        authReq = req.clone({
          body: this.encryptionService.set(jsonBody),
          setHeaders: { Authorization: 'Bearer ' + token}
        });
      } else {
        authReq =
          req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
      }
    }
    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
