import { EventEmitService, GenericEvent, GENERIC_EVENT_TYPE } from './../../shared/services/event-emit.service';
import { ProductService, IProduct } from './../../shared/services/products.service';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';


@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, AfterViewInit {


  productName = '';
  product: IProduct;
  subscriptions: Subscription[] = [] as Subscription[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductService,
    private eventEmitService: EventEmitService) { }

  ngOnInit() {
    this.productName = this.route.snapshot.paramMap.get('productName');
    if (this.productName) {
      this.fetchData(this.productName);
    }
    this.subscriptions.push(this.eventEmitService.events.subscribe((event: GenericEvent) => {
      if (event.type === GENERIC_EVENT_TYPE.UPDATE_PRODUCT) {
        this.fetchData(event.value);
      }
    }));

    if (!this.product) {
      this.productName = this.eventEmitService.lastProduct;
      this.fetchData(this.productName);
    }
  }
  ngAfterViewInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  fetchData(productName) {
    if (productName) {
      this.productName = productName;
      this.productService.get(productName).then(product => {
        this.product = product;
        if (!this.product) {
          // this.router.navigate(['/page-not-found']);
        }
      });
    } else {
      // this.router.navigate(['/page-not-found']);
    }

  }

}
