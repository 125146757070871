import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { EmailService } from 'src/app/shared/services/email.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit, AfterViewInit {

  status = 0;
  supportEmailForm: FormGroup;
  isLoading = false;
  showError = false;
  error = '';

  constructor(
    private router: Router,
    private Activatedroute: ActivatedRoute,
    private fb: FormBuilder,
    private emailService: EmailService,
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {
    this.supportEmailForm.get('wholeSalerType').valueChanges.subscribe((val) => {
      if (val && val === 'Dealer') {
        this.supportEmailForm.get('oemDealerCode').setValidators([
          Validators.required,
          Validators.maxLength(15),
          Validators.pattern('([a-zA-Z0-9]{1,15})')
        ]);
      } else {
        this.supportEmailForm.get('oemDealerCode').clearValidators();
        this.supportEmailForm.get('oemDealerCode').patchValue('');
      }
      this.cdr.detectChanges();
      this.supportEmailForm.get('oemDealerCode').updateValueAndValidity();
    });
  }

  private createSupportEmailForm() {
    this.supportEmailForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(40)]],
      phone: ['', [Validators.required, Validators.pattern('([0-9]{3}[0-9]{3}[0-9]{4})')]],
      email: ['', [Validators.required, Validators.maxLength(40), this.validateEmailAddress]],
      subject: ['', [Validators.required, Validators.maxLength(40)]],
      wholeSalerType: ['', [Validators.required]],
      oemDealerCode: [''],
      description: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.createSupportEmailForm();
    this.Activatedroute.queryParamMap
      .subscribe(params => {
        this.status = +params.get('status') || 0;
      });

    if (this.status === 200) {
      const body = document.getElementById('body');
      body.classList.add('no-scroll');
    }
  }

  sendSupportEmail() {
    this.isLoading = true;
    this.error = '';
    if (this.supportEmailForm.valid) {
      const request = this.supportEmailForm.value;
      this.emailService.sendSupportEmail(request).subscribe(
        (_) => {
          this.status = 200;
          this.supportEmailForm.reset();
          this.isLoading = false;
          this.showError = false;
        }, (_) => {
          this.isLoading = false;
          this.showError = true;
          this.error = 'Sorry, we are unable to send your message.';
        });
    }
  }

  onDismiss() {
    this.status = 0;
    this.router.navigate(['/support']);
    const body = document.getElementById('body');
    if (body.classList.contains('no-scroll')) {
      body.classList.remove('no-scroll');
    }
  }

  validateEmailAddress(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }
    const validEmailPattern = /[^\s@]+@[^\s@]+\.[^\s@]+/gi;
    if (validEmailPattern.test(control.value)) {
      return null;
    } else {
      return { error: true };
    }
  }

  navigateToScreenConnect() {
    this.router.navigate([]).then(_ => {
      window.open('https://oec.screenconnect.com/', '_blank');
    });
  }

}
