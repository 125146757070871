<app-navigation></app-navigation>
<div id="news-content" class="content-box">
  <div class="container">
    <div class="content-title plr-15">
      <h3 class="font-uppercase font-bold">OPS NEWS</h3>
      <div class="line-left orange-bg"></div>
    </div>
    <div class="content-feed">
      <div class="change-ratio" *ngIf="news?.srcType  === 'photo'">
        <img class="img-fluid" [src]="environment + news?.src">
      </div>
      <div *ngIf="news?.srcType == 'video'" class="video-container">
        <iframe width="760" height="428" [src]="news?.src | safe" frameborder="0" allowfullscreen></iframe>
      </div>
      <div class="title font-bold font-uppercase">{{news?.headline}}</div>
      <div class="author-box"><a>By {{news?.author}}</a>&nbsp; {{news?.date}}</div>
      <div class="byline" [innerHTML]="news?.content"></div>
    </div>
  </div>
</div>
<app-footer></app-footer>
